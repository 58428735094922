<template>
  <el-popover
    placement="bottom-start"
    :width="width"
    trigger="manual"
    :visible-arrow="false"
    popper-class="select-popover"
    v-model="visible"
  >
    <div ref="filterSelectPopover" class="filter-select-body">
      <div class="filter-select-container">
        <template v-if="cascader">
          <div :style="{ width: parentWidth + 'px'}" class="select-list-container select-level-parent">
            <div
              v-for="(option, index) in options"
              :key="option.id"
              :class="['option-item', parentIndex === index ? 'active' : '']"
              @click="selectParent(option, index)"
            >
              {{ option[propData.label] }}
            </div>
          </div>
          <div v-if="parentIndex || parentIndex === 0" class="select-list-container select-level-children">
            <div
              v-for="option in options[parentIndex][propData.children]"
              :key="option[propData.value]"
              class="option-item"
              @click="selectOption(option[propData.value])"
            >
              <span class="option-item-label">{{ option[propData.label] }}</span>
              <img v-if="selections.includes(option[propData.value])" :src="checkedIcon" alt="">
              <img v-else :src="checkIcon" alt="">
            </div>
          </div>
        </template>
        <template v-else>
          <div class="select-list-container select-level-children">
            <div
              v-for="option in options"
              :key="option[propData.value]"
              class="option-item"
              @click="selectOption(option[propData.value])"
            >
              <span class="option-item-label">{{ option[propData.label] }}</span>
              <img v-if="selections.includes(option[propData.value])" :src="checkedIcon" alt="">
              <img v-else :src="checkIcon" alt="">
            </div>
          </div>
        </template>
      </div>
      <div class="filter-select-footer">
        <div class="filter-select-footer-btn" @click="confirm">确定</div>
        <div class="filter-select-footer-btn light" @click="reset">重置</div>
      </div>
    </div>
    <div class="filter-select" ref="popoverReference" slot="reference" @click="showPopover">
      <div :class="['select-value', (visible ? !!selections.length : !!value.length) ? 'has-select' : '']">
        {{ showSelections(visible ? selections : value) || placeholder }}
      </div>
      <img class="select-icon" src="../../../assets/spread-2.png" alt="">
    </div>
  </el-popover>
</template>

<script>
// 自定义选择组件
export default {
  name: 'MySelect',
  props:{
    // 数据
    options: {
      type: Array,
      default: () => []
    },
    // 参数
    prop: {
      type: Object,
      default: () => {}
    },
    // 选中值
    value: {
      type: [Array],
      required: true,
    },
    // 默认占位
    placeholder: {
      type: String,
      default: () => ''
    },
    // 是否多级
    cascader: {
      type: Boolean,
      default: () => false
    },
    // 弹框宽度
    width: {
      type: [String, Number],
      default: () => 458
    },
    // 父级选项列宽度
    parentWidth: {
      type: [String, Number],
      default: () => 128
    }
  },
  data() {
    return {
      visible: false,
      defaultPropData: {
        value: 'value',
        label: 'label',
        children: 'children',
        disabled: 'disabled',
        leaf: 'leaf'
      },
      optionMap: {},
      propData: {},
      parentIndex: null,
      parentSelection: {},
      parentSelectValue: null,
      selections: [],
      checkIcon: require('@/assets/check-icon.png'),
      checkedIcon: require('@/assets/checked-icon.png')
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside, false)
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside, false)
  },
  methods: {
    // 点击弹框外
    onClickOutside(e) {
      // 判断是否点击了弹框以为的区域
      if (!e.target.contains(this.$refs.filterSelectPopover)) {
        // 判断是不是点击了弹框触发器
        if (!this.$refs.popoverReference.contains(e.target)) {
          this.visible = false
        }
      }
    },
    // 显示选择框
    showPopover() {
      this.propData = { ...this.defaultPropData, ...this.prop }
      this.fetchOptionMap(this.options)
      this.selections = [...this.value]
      this.visible = true
    },
    fetchOptionMap(list) {
      if (!Array.isArray(list)) return
      if (this.cascader) {
        list.forEach(el => {
          if (!el[this.propData.children] || !Array.isArray(el[this.propData.children]) || !el[this.propData.children].length) return
          el[this.propData.children].forEach(children => {
            this.optionMap[children[this.propData.value]] = children[this.propData.label]
          })
        })
      } else {
        list.forEach(el => {
          this.optionMap[el[this.propData.value]] = el[this.propData.label]
        })
      }
    },
    showSelections(list) {
      if (Array.isArray(list) && list.length) {
        return list.map(key => this.optionMap[key] || '').join(';')
      } else {
        return ''
      }
    },
    // 选择父级选项
    selectParent(textbook, index) {
      this.parentSelection = textbook
      this.parentIndex = index
      this.parentSelectValue = textbook[this.propData.value]
    },
    selectOption(key) {
      if (!this.selections.includes(key)) {
        this.selections.push(key)
      } else {
        this.selections.splice(this.selections.indexOf(key), 1)
      }
    },
    // 确认
    confirm() {
      this.$emit('update:value', this.selections)
      this.$emit('change', this.selections)
      this.visible = false
    },
    // 重置
    reset() {
      this.selections = []
      this.$emit('update:value', [])
      this.$emit('change', [])
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-select{
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  cursor: pointer;
  &+.filter-select{
    margin-left: 20px;
  }
  .select-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &+.select-icon {
      margin-left: 10px;
    }
    &.has-select{
      color: #309AF2;
      font-weight: bold;
    }
  }
  .select-icon{
    width: 11px;
    height: 6px;
  }
}
.filter-select-body{
  max-height: 480px;
  .filter-select-container{
    max-height: 406px;
    display: flex;
    .select-list-container{
      max-height: 406px;
      overflow-y: scroll;
      // 滚动条区域
      &::-webkit-scrollbar{
        width: 0; // 竖向滚动条宽度
        height: 0; // 横向向滚动条高度
        background-color: #FFFFFF;
      }
      &.select-level-parent{
        margin: 20px 0;
        border-right: 1px solid #F1F1F1;
        .option-item{
          padding: 0 14px;
          height: 60px;
          line-height: 60px;
          font-size: 18px;
          background-color: #FFFFFF;
          color: #333333;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.active{
            position: relative;
            background-color: #EAF4FD;
            color: #309AF2;
            font-weight: bold;
            &:before{
              content: '';
              width: 6px;
              height: 100%;
              background-color: #309AF2;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
      &.select-level-children{
        margin: 20px 0;
        flex: 1;
        //width: 330px;
        padding: 0 14px;
        .option-item{
          height: 60px;
          padding: 17px 14px;
          font-size: 18px;
          color: #333333;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover{
            background-color: #F6F6F6;
          }
          .option-item-label{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img{
            margin-left: 20px;
          }
        }
      }
    }
  }
  .filter-select-footer{
    height: 74px;
    padding: 14px 0 20px;
    border-top: 1px solid #F1F1F1;
    display: flex;
    justify-content: center;
    align-items: center;
    .filter-select-footer-btn{
      width: 100px;
      height: 40px;
      padding: 8px 0;
      text-align: center;
      font-size: 17px;
      line-height: 24px;
      color: #FFFFFF;
      border-radius: 8px;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
      cursor: pointer;
      &.light{
        background: #F6F6F6;
        color: #333333;
        &:hover{
          background: #F1F1F1;
        }
      }
      &:hover{
        background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
      }
      &+.filter-select-footer-btn{
        margin-left: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
.select-popover{
  padding: 0;
  border-radius: 16px;
}
</style>
