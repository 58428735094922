<template>
  <article class="assignWork" v-loading="loading">
    <RouterNav :name="name" v-if="superAdmin === '2'"></RouterNav>
    <template v-if="guideCreateClasses">
      <div class="guide-classes-create">
        <div class="icon-classes-none"></div>
        <div style="margin-bottom: 10px;">
          您还没有班级哦~请先前往
          <router-link :to="{name: 'ClassManage'}" class="link-to-classes">创建班级
          </router-link>
        </div>
        <div>或致电：400-996-0201，我们的客服人员会为您创建</div>
      </div>
    </template>
    <template v-else>
      <div class="scroll1">
        <article class="assign" >
          <section class="module">
            <!-- 一级模块 -->
            <div v-for="(item) in moduleList" :class="['module-item', moduleId === item.id ? 'item-active' : '']" :key="item.id" @click="changeModule(item)">
              <div class="module-item-icon">
                <img :src="item.mainPhoto" alt="icon">
              </div>
              <div>{{ item.name }}</div>
            </div>
          </section>
        </article>
        <!-- 教辅 -->
        <template v-if="activeModule.moduleClassify === 2">
          <div v-if="showBookDetail" class="book-info">
            <img class="book-main-photo" :src="book.mainPhoto" alt="" width="48" height="63">
            <span class="book-name">{{ book.name }}</span>
            <div class="book-btn" @click="switchoverBook">
              <span>换本书</span>
              <img src="../../assets/switchover.png" alt="" width="16" height="16">
            </div>
          </div>
          <div v-else class="book-module-container">
            <div class="book-module-list">
              <div v-for="grade in gradeList" :key="grade.id" :class="['book-module-item', gradeId === grade.id ? 'active' : '']" @click="changeGrade(grade)">
                {{ grade.name }}
              </div>
            </div>
            <div class="book-list">
              <div v-for="bookItem in bookList" :key="bookItem.materialId" class="book-item" @click="clickBook(bookItem)">
                <img :src="bookItem.mainPhoto" alt="" class="book-item-img" width="109" height="144">
                <div class="book-item-content">
                  <div class="book-item-name">{{ bookItem.name }}</div>
                </div>
              </div>
            </div>
            <div class="list-footer">没有更多了哦~</div>
          </div>
        </template>
        <!-- 枝干模块 -->
        <template v-if="!(activeModule.moduleClassify === 2 && !showBookDetail)">
          <div v-if="stemModuleList && stemModuleList.length" class="stem-module-container">
            <div class="stem-module-list">
              <div v-for="(stem, index) in stemModuleList" :key="stem.id" :class="['stem-module-item', stemFirstLevelIndex === index ? 'active' : '']" @click="changeStemModule(stem, index)">
                {{ stem.name }}
              </div>
            </div>
            <!-- 若枝干模块有两层 -->
            <div v-if="sublevelModuleList && sublevelModuleList.length" class="stem-module-list">
              <div v-for="stem in sublevelModuleList" :key="stem.id" :class="['stem-module-children-item', stemModule.id === stem.id ? 'active' : '']" @click="changeSublevelModule(stem)">
                {{ stem.name }}
              </div>
            </div>
          </div>
        </template>
        <!-- 教材下模块 -->
        <div v-if="activeModule.moduleClassify === 3" class="textbook-module">
          <div class="level-item">
            <div class="level-item-label">版本</div>
            <div class="level-item-value">
              <div v-for="module in textbookTreeList" :key="module.id" :class="['module-item', versionsModule.id === module.id ? 'active' : '']" @click="changeVersions(module)">{{ module.name }}</div>
            </div>
          </div>
          <div class="level-item">
            <div class="level-item-label">教材</div>
            <div class="level-item-value">
              <div v-for="module in versionsModule.textbookList" :key="module.id" :class="['module-item', textbookModule.id === module.id ? 'active' : '']" @click="changeTextbook(module)">{{ module.name }}</div>
            </div>
          </div>
          <div class="level-item">
            <div class="level-item-label">单元</div>
            <div class="level-item-value">
              <div v-for="module in textbookUnitList" :key="module.id" :class="['module-item', textbookUnitModule.id === module.id ? 'active' : '']" @click="changeTextbookUnit(module)">{{ module.name }}</div>
            </div>
          </div>
        </div>
        <!-- 模块内容 -->
        <div v-if="showBookDetail" class="modal-container">
          <div class="modal-container-content">
            <!-- 教材下模块类型 -->
            <template v-if="activeModule.moduleClassify === 3">
              <div v-if="leafModuleList.length" class="leaf-modal-list">
                <div v-for="module in leafModuleList" :key="module.id">
                  <div v-if="module.hasContent" :class="['leaf-modal', leafModule.id === module.id ? 'active' : '']" @click="changeLeafModule(module)">
                    {{ module.name }}
                  </div>
                </div>
              </div>
            </template>
            <!-- 音标/拓展 类型 -->
            <template v-else-if="activeModule.moduleClassify === 1 && [1, 4].includes(activeModule.moduleContentType)">
              <div v-if="classifyList.length" class="leaf-modal-list">
                <div v-for="classify in classifyList" :key="classify.classifyId" :class="['leaf-modal', activeClassify.classifyId === classify.classifyId ? 'active' : '']" @click="changeClassify(classify)">
                  {{ classify.classifyName }}
                </div>
              </div>
            </template>
            <!-- 叶子模块 -->
            <template v-else>
              <div v-if="leafModuleList.length" class="leaf-modal-list">
                <div v-for="module in leafModuleList" :key="module.id" :class="['leaf-modal', leafModule.id === module.id ? 'active' : '']" @click="changeLeafModule(module)">
                  {{ module.shortName || module.name }}
                </div>
              </div>
            </template>
            <!-- 模块内容列表 -->
            <div :class="['leaf-modal-content', leafModule.moduleContentType === 5 && leafModule.key === 'simple' ? 'has-header' : '']">
              <!-- 音标 -->
              <template v-if="leafModule.moduleContentType === 1 && activeClassify.pronList && activeClassify.pronList.length">
                <div
                  v-infinite-scroll="loadMore"
                  :infinite-scroll-disabled="Classify2ListDisabled"
                  :infinite-scroll-delay="300"
                  :infinite-scroll-distance="50"
                >
                  <div v-for="pron in activeClassify.pronList" :key="pron.groupId" class="module-item">
                    <div class="module-item-container">
                      <span class="module-item-name">{{ pron.name || '' }}</span>
                      <div class="module-item-btn light-btn" @click="toCrrangement(pron)">预览</div>
                      <div :class="['module-item-btn', pron.selected ? 'delete' : '']" @click="addList(pron, moduleId)">{{ pron.selected ? '移除清单' : '加入清单' }}</div>
                    </div>
                  </div>
                  <div v-if="loading" class="list-footer">加载中……</div>
                  <div v-else-if="activeClassify.noMore" class="list-footer">没有更多了哦~</div>
                </div>
              </template>
              <!-- 单词 -->
              <template v-if="leafModule.moduleContentType === 2 && leafModule.wordModuleData">
                <!-- 单词模块 -->
                <WordModule
                  v-for="(testlets, index) in leafModule.wordModuleData"
                  :key="testlets.wordUnitId"
                  :moduleContentType="leafModule.moduleContentType"
                  :index="index"
                  :testlets="testlets"
                  :selectedList="testlets.selectedList"
                  :typeList="testlets.typeList"
                  @changeAllSelected="changeAllSelected"
                  @selectWord="selectWord"
                  @changeType="changeType"
                />
                <div class="content-footer">
                  <span class="estimated-time">预计用时：{{ estimatedTime }}分钟</span>
                  <div class="content-footer-btn" @click="submit">加入清单</div>
                </div>
              </template>

              <!-- 新单词 -->
              <template v-if="leafModule.moduleContentType === 13 && leafModule.wordGroupModuleData && leafModule.wordGroupModuleData.length">
                <!-- 新单词模块 -->
                <WordModule
                    v-for="(testlets, index) in leafModule.wordGroupModuleData"
                    :key="testlets.wordGroupId"
                    :moduleContentType="leafModule.moduleContentType"
                    :index="index"
                    :testlets="testlets"
                    :selectedList="testlets.selectedList"
                    :typeList="testlets.typeList"
                    @changeAllSelected="changeAllSelected"
                    @selectWord="selectWord"
                    @changeType="changeType"
                />
                <div class="content-footer">
                  <span class="estimated-time">预计用时：{{ estimatedTime }}分钟</span>
                  <div class="content-footer-btn" @click="submit">加入清单</div>
                </div>
              </template>
              <!-- 课文 -->
              <template v-if="leafModule.moduleContentType === 3 && leafModule.readModuleData && leafModule.readModuleData.length">
                <!-- 课文模块 -->
                <TextModule
                  v-for="readModule in leafModule.readModuleData"
                  :key="readModule.repeatAfterId"
                  :id="readModule.repeatAfterId"
                  :testlets="readModule"
                  :moduleContentType="leafModule.moduleContentType"
                  :typeList="readModule.typeList"
                  @changeType="changeType"
                />
                <div class="content-footer">
                  <span class="estimated-time">预计用时：{{ estimatedTime }}分钟</span>
                  <div class="content-footer-btn" @click="submit">加入清单</div>
                </div>
              </template>

              <!-- 新课文 -->
              <template v-if="leafModule.moduleContentType === 14 && leafModule.textbookGroupModuleData && leafModule.textbookGroupModuleData.length">
                <!-- 课文模块 -->
                <TextModule
                    v-for="readModule in leafModule.textbookGroupModuleData"
                    :key="readModule.id"
                    :id="readModule.id"
                    :testlets="readModule"
                    :moduleContentType="leafModule.moduleContentType"
                    :typeList="readModule.typeList"
                    @changeType="changeType"
                />
                <div class="content-footer">
                  <span class="estimated-time">预计用时：{{ estimatedTime }}分钟</span>
                  <div class="content-footer-btn" @click="submit">加入清单</div>
                </div>
              </template>

              <!-- 拓展 -->
              <template v-if="leafModule.moduleContentType === 4 && activeClassify.repeatAfterList">
                <div
                  class="scroll dub-testlets-list"
                  v-infinite-scroll="loadMore"
                  :infinite-scroll-disabled="Classify2ListDisabled"
                  :infinite-scroll-delay="300"
                  :infinite-scroll-distance="50"
                >
                  <div v-for="repeatAfter in activeClassify.repeatAfterList" :key="repeatAfter.id" class="testlets dub-testlets" @click="clickRepeatAfter(repeatAfter)">
                    <div class="testlets-cover">
                      <img :src="repeatAfter.photoUrl" alt="" class="testlets-cover-img">
                    </div>
                    <div class="testlets-name">{{ repeatAfter.name }}</div>
                  </div>
                </div>
                <div v-if="loading" class="list-footer">加载中……</div>
                <div v-else-if="activeClassify.noMore" class="list-footer">没有更多了哦~</div>
              </template>
              <!-- 配音 -->
              <template v-if="leafModule.moduleContentType === 5">
                <!-- 教材下配音 -->
                <template v-if="leafModule.dubModuleData">
                  <div class="dub-testlets-list">
                    <div v-for="dubModule in leafModule.dubModuleData" :key="dubModule.materialId" class="testlets dub-testlets" @click="clickDub(dubModule)">
                      <div class="testlets-cover">
                        <img :src="dubModule.coverUrl" alt="" class="testlets-cover-img">
                        <div class="testlets-info">
                          <img src="../../assets/microphone.png" alt="" class="testlets-info-img">
                          <span class="sentence-count">{{ dubModule.sentenceCount }}句</span>
                          <span class="video-duration">{{ common.timeFormat(Math.ceil(dubModule.videoDuration)) }}</span>
                        </div>
                      </div>
                      <div class="testlets-name">{{ dubModule.materialName }}</div>
                    </div>
                  </div>
                </template>
                <!-- 配音模块-素材 -->
                <template v-else-if="leafModule.key === 'simple' && leafModule.simpleList">
                  <div class="dub-testlets-header">
                    <MySelect
                      ref="textbookRef"
                      :value.sync="dubFilterParam.textbookIds"
                      :options="textbookTree"
                      :width="282"
                      :parentWidth="94"
                      :cascader="true"
                      :prop="{ value: 'id', label: 'name', children: 'textbookList'}"
                      placeholder="教材"
                      @change="changeSimpleParams"
                    ></MySelect>
                    <MySelect
                      :value.sync="dubFilterParam.tagIds"
                      :options="themeOption"
                      :cascader="true"
                      :prop="{ value: 'id', label: 'name', children: 'tags'}"
                      placeholder="主题"
                      @change="changeSimpleParams"
                    ></MySelect>
                    <MySelect
                      :value.sync="dubFilterParam.themeTypes"
                      :options="typeOption"
                      width="276"
                      :prop="{ value: 'value', label: 'name'}"
                      placeholder="类型"
                      @change="changeSimpleParams"
                    ></MySelect>
                  </div>
                  <template v-if="leafModule.simpleList && leafModule.simpleList.length">
                    <div
                      class="scroll dub-testlets-list"
                      v-infinite-scroll="loadMoreList"
                      :infinite-scroll-disabled="leafModuleDisabled"
                      :infinite-scroll-delay="300"
                      :infinite-scroll-distance="50"
                    >
                      <div v-for="simple in leafModule.simpleList" :key="simple.id" class="testlets dub-testlets" @click="clickDub(simple)">
                        <div class="testlets-cover">
                          <img :src="simple.coverUrl" alt="" class="testlets-cover-img">
                          <div class="testlets-info">
                            <img src="../../assets/microphone.png" alt="" class="testlets-info-img">
                            <span class="sentence-count">{{ simple.sentenceCount }}句</span>
                            <span class="video-duration">{{ common.timeFormat(Math.ceil(simple.videoDuration)) }}</span>
                          </div>
                        </div>
                        <div class="testlets-name">{{ simple.materialName }}</div>
                      </div>
                    </div>
                    <div v-if="loading" class="list-footer">加载中……</div>
                    <div v-else-if="leafModule.noMore" class="list-footer">没有更多了哦~</div>
                  </template>
                  <template v-else>
                    <div class="empty">
                      <img src="../../assets/empty.png" alt=""  width="250" height="160">
                      <div class="empty-description">找不到资源，修改下筛选条件试试</div>
                    </div>
                  </template>
                </template>
                <!-- 配音模块-专辑 -->
                <template v-else-if="leafModule.key === 'albumPage' && leafModule.albumPageList && leafModule.albumPageList.length">
                  <template v-if="albumVisible">
                    <div class="album-deatil">
                      <div class="album-header">
                        <img class="album-head-photo" :src="albumDeatil.coverPicture" alt="" width="100" height="100">
                        <div class="album-info">
                          <div class="album-name">{{ albumDeatil.name }}</div>
                          <div class="album-author">
                            <img class="album-author-img" :src="albumDeatil.headPhotoUrl" alt="" width="26" height="26">
                            <span class="album-author-name">{{ albumDeatil.authorName }}</span>
                          </div>
                          <!-- 展开效果 TODO--封装成组件使用 -->
                          <div class="dub-description">
                            <input type="checkbox" id="exp" />
                            <div class="dub-description-value">
                              <label class="fold-btn" for="exp"></label>
                              <span v-html="albumDeatil.introduction" />
                            </div>
                          </div>
                        </div>
                        <div class="backtrack" @click="backtrack">
                          <img src="../../assets/backtrack.png" alt="" width="20" height="20">
                          <span class="backtrack-value">返回专辑列表</span>
                        </div>
                      </div>
                      <div class="album-Material-list dub-testlets-list">
                        <div v-for="videoMaterial in albumDeatil.videoMaterialList" :key="videoMaterial.id" class="testlets dub-testlets" @click="clickDub(videoMaterial)">
                          <div class="testlets-cover">
                            <img :src="videoMaterial.coverUrl" alt="" class="testlets-cover-img">
                            <div class="testlets-info">
                              <img src="../../assets/microphone.png" alt="" class="testlets-info-img">
                              <span class="sentence-count">{{ videoMaterial.sentenceCount }}句</span>
                              <span class="video-duration">{{ common.timeFormat(Math.ceil(videoMaterial.videoDuration)) }}</span>
                            </div>
                          </div>
                          <div class="testlets-name">{{ videoMaterial.materialName }}</div>
                        </div>
                      </div>
                      <div class="list-footer">没有更多了哦~</div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="album-list"
                      v-infinite-scroll="loadMoreList"
                      :infinite-scroll-disabled="leafModuleDisabled"
                      :infinite-scroll-delay="300"
                      :infinite-scroll-distance="50"
                    >
                      <div v-for="albumPage in leafModule.albumPageList" :key="albumPage.materialId" class="album-item" @click="clickAlbum(albumPage)">
                        <img :src="albumPage.coverPicture" alt="" class="album-item-img">
                        <div class="album-item-content">
                          <div class="album-item-name">{{ albumPage.name }}</div>
                          <div class="album-item-num">更新至{{ albumPage.materialNum }}集</div>
                        </div>
                      </div>
                    </div>
                    <div v-if="loading" class="list-footer">加载中……</div>
                    <div v-else-if="leafModule.noMore" class="list-footer">没有更多了哦~</div>
                  </template>
                </template>
              </template>
              <!-- 听说、笔试 -->
              <template v-if="[6, 7].includes(leafModule.moduleContentType)">
                <template v-if="activeModule.moduleClassify === 3">
                  <div v-for="groupModule in leafModule.groupModuleData" :key="groupModule.groupId" class="module-item">
                    <div class="module-item-container">
                      <span class="module-item-name">{{ groupModule.shortName || groupModule.groupName }}</span>
                      <div v-if="groupModule.groupType === 2" class="module-item-btn" @click="toCrrangement(groupModule)">前往布置</div>
                      <template v-else>
                        <div class="module-item-btn light-btn" @click="toCrrangement(groupModule)">预览</div>
                        <div :class="['module-item-btn', groupModule.selected ? 'delete' : '']" @click="addList(groupModule, leafModule.id)">{{ groupModule.selected ? '移除清单' : '加入清单' }}</div>
                      </template>
                    </div>
                  </div>
                  <div class="list-footer">没有更多了哦~</div>
                </template>
                <template v-else>
                  <template v-for="groupModule in groupList">
                    <template v-if="groupModule.hasChildren">
                      <div :key="groupModule.groupId" class="module-item">
                        <div class="module-item-container" @click="changeFold(groupModule)">
                          <span class="module-item-name">{{ groupModule.shortName || groupModule.name }}</span>
                          <div class="fold-icon">
                            <img v-if="groupModule.unfold" src="../../assets/top_arrow_2.png" alt="" width="14" height="8">
                            <img v-else src="../../assets/bottom_arrow_2.png" alt="" width="14" height="8">
                          </div>
                        </div>
                      </div>
                      <template v-if="groupModule.unfold">
                        <div v-for="unitGroup in groupModule.unitGroupList" :key="unitGroup.id" class="module-item">
                          <div class="module-item-container">
                            <span class="module-item-name">{{ unitGroup.shortName || unitGroup.name }}</span>
                            <div v-if="unitGroup.groupType === 2" class="module-item-btn" @click="toCrrangement(unitGroup)">前往布置</div>
                            <template v-else>
                              <div class="module-item-btn light-btn" @click="toCrrangement(unitGroup)">预览</div>
                              <div :class="['module-item-btn', unitGroup.selected ? 'delete' : '']" @click="addList(unitGroup, leafModule.id)">{{ unitGroup.selected ? '移除清单' : '加入清单' }}</div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div :key="groupModule.groupId" class="module-item">
                        <div class="module-item-container">
                          <span class="module-item-name">{{ groupModule.shortName || groupModule.name }}</span>
                          <div v-if="groupModule.groupType === 2" class="module-item-btn" @click="toCrrangement(groupModule)">前往布置</div>
                          <template v-else>
                            <div class="module-item-btn light-btn" @click="toCrrangement(groupModule)">预览</div>
                            <div :class="['module-item-btn', groupModule.selected ? 'delete' : '']" @click="addList(groupModule, leafModule.id)">{{ groupModule.selected ? '移除清单' : '加入清单' }}</div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div class="list-footer">没有更多了哦~</div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>

      <Trolley @listState="listState"></Trolley>

      <div class="msg-bg" v-show="teacherStatus !== '2'"></div>
      <div class="msg-box" v-show="teacherStatus !== '2'">当前账号正在审核，暂不能使用此功能。
        如有疑问，请致电：400-996-0201
      </div>

    </template>
    <!-- 配音预览弹框 -->
    <DubDialog ref="dubDialogRef" :dubDetail="dubDetail" :dubModule="dubModule" @submit="dubSubmit"></DubDialog>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import {
  dubTypeEnum,
  flag2moduleContentTypeEnum,
  moduleIdByGradeTypeIdeEnum,
  PracticeModeEnum,
  TeachingAssistantEnum
} from '@/utils/enum.js'
import WordModule from '@/views/homework/components/WordModule.vue'
import TextModule from '@/views/homework/components/TextModule.vue'
import Trolley from './assign/components/trolley.vue'
import MySelect from '@/views/homework/components/MySelect.vue'
import DubDialog from '@/views/homework/components/DubDialog.vue'
import Decimal from 'decimal.js'
import _ from 'lodash'
import {getClassesList} from "@/api/classes.js";
import utils from '@/utils/utils.js'

export default {
  name: 'assignWork',
  components: {
    RouterNav,
    WordModule,
    TextModule,
    Trolley,
    MySelect,
    DubDialog
  },
  data() {
    return {
      name: '布置作业',
      loading: false,
      pageSize: 20,
      moduleList: [],
      activeModule: '',
      moduleId: '',
      /* 教材 */
      textbookTreeList: [],
      versionsModule: {},
      textbookModule: {},
      textbookUnitList: [],
      textbookUnitModule: {},
      practiceMode: PracticeModeEnum,
      estimatedTime: 0,
      dubDetail: {},
      dubModule: {},
      /* 音标 */
      classifyList: [],
      activeClassify: {},

      stemModuleList: [], // 枝干模块列表
      sublevelModuleList: [], // 次级枝干模块列表
      stemModule: {}, // 选中的最后一级枝干模块
      stemFirstLevelIndex: null,
      leafModuleList: [], // 不管有多少级模块，这个只放最后一级模块列表
      leafModule: {}, // 最后一级模块
      groupList: [],

      textbookTree: [], // 趣味配音教材数据
      themeOption: [], // 趣味配音主题数据
      typeOption: [], // 趣味配音类型数据
      dubFilterParam: {
        textbookIds: [],
        themeTypes: [],
        tagIds: []
      },
      albumPageData: {},
      albumDeatil: {},
      albumVisible: false,

      gradeList: [],
      gradeId: '',
      bookList: [],
      book: {},
      showBookDetail: true,


      // moduleIndex: 0,
      list: [],
      flag: '',
      spread: false,
      inventoryHeight: 0,
      pageIndex: 1,
      teacherStatus: 1,
      guideCreateClasses: false, // 是否引导跳转创建班级
      visible: false,
      superAdmin: 0,
    }
  },
  computed: {
    time: function () {
      const {selectedWorkList} = this.$store.state
      let time = 0
      Object.keys(selectedWorkList).forEach((item) => {
        time += selectedWorkList[item].time
      })
      return time
    },
    selectedWork () {
      return this.$store.state.selectedWorkList
    },
    Classify2ListDisabled () {
      return this.loading || this.activeClassify.noMore
    },
    leafModuleDisabled () {
      return this.loading || this.leafModule.noMore
    },
    teacherId() {
      return sessionStorage.getItem('teacherId')
    },
    gradeTypeId() {
      return sessionStorage.getItem('gradeTypeId')
    }
  },
  watch: {
    spread: function (val) {
      this.inventoryHeight = val ? 'auto' : 0
    },
    selectedWork() {
      this.echoData()
    }
  },
  mounted() {
    this.superAdmin = sessionStorage.getItem('superAdmin')
    this.teacherStatus = sessionStorage.getItem('teacherStatus')
    if (this.teacherStatus !== '2') {
      this.$alert('当前账号正在审核，暂不能使用此功能。<br />如有疑问，请致电：400-996-0201', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定'
      })
    } else {
      const superAdmin = sessionStorage.getItem('superAdmin')
      if (superAdmin !== '2') {
        getClassesList().then(res => {
          if (res.data && res.data.length) {
            this.moduleId = this.$route.query.id || ''
            this.getBaseModule()
          } else {
            this.guideCreateClasses = true // 非超级教师且无班级
          }
        })
      } else {
        this.moduleId = this.$route.query.id || ''
        this.getBaseModule()
      }
    }
  },
  activated() {
    this.echoData()
  },
  methods: {
    // 处理回显数据
    echoData() {
      const selectedWorkList = this.$store.state.selectedWorkList
      // const {preliminWritten} = this.common.globalData
      if (!this.leafModule || !this.leafModule.id) return
      if (this.activeModule.moduleClassify === 3) {
        switch (this.leafModule.moduleContentType) {
          case 2:
            this.leafModule.wordModuleData && this.leafModule.wordModuleData.forEach(el => {
              if (selectedWorkList[el.wordUnitId]) {
                el.selectedList = selectedWorkList[el.wordUnitId].wordList.map(word => word.id)
                el.typeList = selectedWorkList[el.wordUnitId].typeList.map(type => type.id)
                el.allSelected = el.selectedList.length === el.wordList.length
              } else {
                el.selectedList = []
                el.typeList = []
                el.allSelected = false
              }
            })
            this.setEstimatedTime()
            break
          case 3:
            this.leafModule.readModuleData && this.leafModule.readModuleData.forEach(el => {
              if (selectedWorkList[el.repeatAfterId]) {
                el.oldTypeList = selectedWorkList[el.repeatAfterId].typeList
                el.typeList = selectedWorkList[el.repeatAfterId].typeList.map(type => type.id)
              } else {
                el.oldTypeList = []
                el.typeList = []
              }
            })
            this.setEstimatedTime()
            break
          case 5:
            this.leafModule.dubModuleData && this.leafModule.dubModuleData.forEach(el => {
              el.selected = !!selectedWorkList[el.materialId]
            })
            this.$forceUpdate()
            break
          case 6:
            this.leafModule.groupModuleData && this.leafModule.groupModuleData.forEach(el => {
              el.selected = !!selectedWorkList[el.groupId]
            })
            this.$forceUpdate()
            break
          case 13:
            this.leafModule.wordGroupModuleData && this.leafModule.wordGroupModuleData.forEach(el => {
              if (selectedWorkList[el.wordGroupId]) {
                el.selectedList = selectedWorkList[el.wordGroupId].wordList.map(word => word.id)
                el.typeList = selectedWorkList[el.wordGroupId].typeList.map(type => type.id)
                el.allSelected = el.selectedList.length === el.wordList.length
              } else {
                el.selectedList = []
                el.typeList = []
                el.allSelected = false
              }
            })
            this.setEstimatedTime()
            break
          case 14:
            this.leafModule.textbookGroupModuleData && this.leafModule.textbookGroupModuleData.forEach(el => {
              if (selectedWorkList[el.id]) {
                el.oldTypeList = selectedWorkList[el.id].typeList
                el.typeList = selectedWorkList[el.id].typeList.map(type => type.id)
              } else {
                el.oldTypeList = []
                el.typeList = []
              }
            })
            this.setEstimatedTime()
            break
          default:
            break
        }
      } else if (this.activeModule.moduleClassify === 1) {
        switch (this.leafModule.moduleContentType) {
          case 1:
          case 4:
            this.activeClassify.pronList && this.activeClassify.pronList.forEach(el => {
              el.selected = !!selectedWorkList[el.id]
            })
            this.$forceUpdate()
            break
          case 6:
            this.groupList && this.groupList.length && this.groupList.forEach(el => {
              el.selected = !!selectedWorkList[el.id]
              if (el.unitGroupList?.length) {
                el.unitGroupList.forEach(item => {
                  item.selected = !!selectedWorkList[item.id]
                })
              }
            })
            this.$forceUpdate()
            break
          case 5:
            if (this.leafModule.key === 'simple') {
              this.leafModuleList[this.leafModule.index].simpleList.forEach(el => {
                el.selected = !!selectedWorkList[el.materialId]
              })
              this.leafModule.simpleList = this.leafModuleList[this.leafModule.index].simpleList
            } else if (this.leafModule.key === 'albumPage') {
              this.albumDeatil.videoMaterialList && this.albumDeatil.videoMaterialList.forEach(el => {
                el.selected = !!selectedWorkList[el.materialId]
              })
            }
            break
          default:
            break
        }
      } else if (this.activeModule.moduleClassify === 2) {
        this.groupList && this.groupList.length && this.groupList.forEach(el => {
          el.selected = !!selectedWorkList[el.id]
          if (el.unitGroupList && el.unitGroupList.length) {
            el.unitGroupList.forEach(item => {
              item.selected = !!selectedWorkList[item.id]
            })
          }
        })
        this.$forceUpdate()
      }
    },
    // 获取布置作业的模块列表
    getBaseModule() {
      const data = {
        channelKey: 'channel-ZUOY',
        gradeId: sessionStorage.getItem('gradeTypeId'),
        provinceId: sessionStorage.getItem('provinceId'),
        cityId: sessionStorage.getItem('cityId')
      }
      const schoolId = sessionStorage.getItem('schoolId')
      if (schoolId) data.schoolId = schoolId
      this.$axios({
        method: 'post',
        url: this.$urls.getFirstLevelModuleByChannel,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.state === '11') {
          const {data} = response
          if (!data || !data.length) return
          this.moduleList = _.orderBy(data, 'seq', 'asc')
          const moduleData = this.moduleList.find(el => el.id === this.moduleId)
          this.changeModule(moduleData || this.moduleList[0])
          this.$store.commit('includeAdd', 'assignWork')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取  分类列表
    getClassifyList(type) {
      let url = ''
      if (type === 'pron') {
        url = this.$urls.getClassifyList
      } else if (type === 'repeatAfter') {
        url = this.$urls.getRepeatAfterClassifyList
      }
      if (!url) return
      this.$axios({
        method: 'post',
        url: url,
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.state !== '11') return
        const arr = response.data || []
        if (type === 'repeatAfter') {
          arr.unshift({
            classifyId: null,
            classifyName: "全部",
            seq: 0
          })
        }
        this.classifyList = _.orderBy(arr, 'seq', 'asc').map((el, index) => {
          el.index = index
          el.pageIndex = 1
          el.noMore = false
          if (type === 'pron') {
            el.pronList = []
          } else if (type === 'repeatAfter') {
            el.repeatAfterList = []
          }
          return el
        })
        if (this.classifyList.length) this.changeClassify(this.classifyList[0])
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取音标列表
    fetchPronList(classify) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.pronList,
        data: {
          classifyId: classify.classifyId,
          pageIndex: classify.pageIndex,
          pageSize: this.pageSize
        }
      }).then((response) => {
        if (response.state === '11') {
          if (response.data && response.data.length) {
            this.activeClassify.pronList = (this.activeClassify.pronList || []).concat(response.data)
            this.activeClassify.noMore = false
            this.echoData()
          } else {
            this.activeClassify.noMore = true
          }
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        moduleList[moduleIndex].pageIndex -= 1
      })
    },
    // 获取拓展列表
    fetchRepeatAfterList(classify) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.repeatAfter,
        data: {
          moduleId: moduleIdByGradeTypeIdeEnum[this.gradeTypeId], // 拓展阅读/时文阅读的id，分初高中。高中： 607D0C5A8FD44C91834071857F9BD61A；初中：72B3DBA3A89F436A9FD765114A724DA5
          teacherId: this.teacherId,
          classifyId: classify.classifyId,
          pageIndex: classify.pageIndex,
          pageSize: this.pageSize
        }
      }).then((response) => {
        if (response.state === '11') {
          if (response.data && response.data.length) {
            this.activeClassify.repeatAfterList = (this.activeClassify.repeatAfterList || []).concat(response.data)
            this.activeClassify.noMore = false
            this.echoData()
          } else {
            this.activeClassify.noMore = true
          }
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        moduleList[moduleIndex].pageIndex -= 1
      })
    },
    // 教材--全部教材版本和教材的树形结构
    fetchTextbookTreeList() {
      this.textbookTreeList = []
      this.versionsModule = {}
      this.textbookModule = {}
      this.textbookUnitList = []
      this.leafModuleList = []
      this.leafModule = {}
      this.estimatedTime = 0
      const gradeTypeId = sessionStorage.getItem('gradeTypeId')
      const { provinceId, cityId } = this.$store.state.userInfo
      this.$axios({
        method: 'post',
        url: this.$urls.textbookTreeList,
        headers: { 'Content-Type': 'application/json' },
        data: {
          section: gradeTypeId === '7' ? 1 : 2,
          provinceId,
          cityId: cityId || -1,
          rangeMatch: true
        }
      }).then(response => {
        if (response.state !== '11') return
        this.textbookTreeList = (response.data || []).filter(el => el.textbookList && el.textbookList.length)
        if (this.textbookTreeList.length) {
          this.changeVersions(this.textbookTreeList[0])
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取教材下面单元列表
    fetchTextbookUnitList(textbookId) {
      this.$axios({
        method: 'post',
        url: this.$urls.getTextbookUnitList,
        headers: { 'Content-Type': 'application/json' },
        data: {
          textbookId
        }
      }).then(response => {
        if (response.state !== '11') return
        this.textbookUnitList = response.data || []
        if (this.textbookUnitList.length) {
          this.textbookUnitModule = this.textbookUnitList[0]
          let textbookUnitIds = null
          if (this.textbookUnitModule.children && this.textbookUnitModule.children.length) {
            textbookUnitIds = this.textbookUnitModule.children.map(el => el.id)
          }
          this.fetchListPaperGroup(this.textbookUnitModule.id, textbookUnitIds)
        } else {
          this.textbookUnitModule = {}
          this.leafModuleList = []
          this.leafModule = {}
          this.estimatedTime = 0
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取教材单元下面的试题数据
    fetchListPaperGroup(id, textbookUnitIds) {
      this.leafModuleList = []
      this.leafModule = {}
      this.estimatedTime = 0
      const paramData = {
        moduleId: this.moduleId,
        pidUnitId: id
      }
      if (textbookUnitIds) paramData.textbookUnitIds = textbookUnitIds
      this.$axios({
        method: 'post',
        url: this.$urls.getTextbookSyncByModule,
        headers: { 'Content-Type': 'application/json' },
        data: paramData
      }).then(response => {
        if (response.state !== '11') return
        const list = response.data || []
        const arr = _.sortBy(list, 'seq')
        arr.forEach((el, index) => {
          el.index = index
          switch (el.moduleContentType) {
            case 2: /* 单词wordModuleData */
              el.wordModuleData?.length && el.wordModuleData.forEach(wordModule => wordModule.flag = flag2moduleContentTypeEnum[el.moduleContentType])
              break
            case 3: /* 课文readModuleData */
              el.readModuleData?.length && el.readModuleData.forEach(readModule => readModule.flag = flag2moduleContentTypeEnum[el.moduleContentType])
              break
            case 5: /* 配音dubModuleData */
              el.dubModuleData?.length && el.dubModuleData.forEach(dubModule => dubModule.flag = flag2moduleContentTypeEnum[el.moduleContentType])
              break
            case 6: /* 听说读写groupModuleData */
              if (el.groupModuleData?.length) {
                el.groupModuleData.forEach(groupModule => {
                  groupModule.flag = groupModule.groupType === 1 ? 5 : 4
                })
              }
              break
            case 13: /* 新单词wordGroupModuleData */
              el.wordGroupModuleData?.length && el.wordGroupModuleData.forEach(wordModule => wordModule.flag = flag2moduleContentTypeEnum[el.moduleContentType])
              break
            case 14: /* 新课文textbookGroupModuleData */
              el.textbookGroupModuleData?.length && el.textbookGroupModuleData.forEach(readModule => readModule.flag = flag2moduleContentTypeEnum[el.moduleContentType])
              break
            default:
              break
          }
          el.hasContent = this.hasContent(el)
        })
        this.leafModuleList = arr
        this.changeLeafModule(this.leafModuleList.find(el => el.hasContent) || {})
        // this.changeLeafModule(this.leafModuleList[0] || {})
      }).catch((err) => {
        console.log(err)
      })
    },
    // 普通模块
    fetchModuleTreeByPid(id) {
      const data = {
        moduleId: id,
        gradeId: sessionStorage.getItem('gradeTypeId'),
        provinceId: sessionStorage.getItem('provinceId'),
        cityId: sessionStorage.getItem('cityId')
      }
      const schoolId = sessionStorage.getItem('schoolId')
      if (schoolId) data.schoolId = schoolId
      this.stemModuleList = []
      this.stemFirstLevelIndex = null
      this.leafModuleList = []
      this.$axios({
        method: 'post',
        url: this.$urls.getModuleTreeByPid,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.state !== '11') return
        this.stemModuleList = response.data || []
        this.processingStemModule(this.stemModuleList, 1)
        if (this.stemModuleList.length) this.changeStemModule(this.stemModuleList[0], 0)
      })
    },
    // 获取趣味配音模块的筛选数据
    fetchMaterialOptionData() {
      const gradeTypeId = sessionStorage.getItem('gradeTypeId')
      const defaultParam = {
        method: 'post',
        url: '',
        headers: { 'Content-Type': 'application/json' },
        data: {}
      }
      const { provinceId, cityId } = this.$store.state.userInfo
      // 趣味配音教材数据
      const fetchTextbookOption = this.$axios({
        ...defaultParam,
        url: this.$urls.textbookTreeList,
        data: {
          section: gradeTypeId === '7' ? 1 : 2,
          provinceId,
          cityId: cityId || -1,
          rangeMatch: true
        }
      })
      // 趣味配音主题数据
      const fetchThemeOption = this.$axios({
        ...defaultParam,
        url: this.$urls.listAllWithTag,
        data: { tagCategoryType: 1 }
      })
      // 趣味配音类型数据
      const fetchTypeOption = this.$axios({
        ...defaultParam,
        url: this.$urls.findListByParam,
        data: { param: 'video_theme_type' }
      })
      Promise.all([fetchTextbookOption, fetchThemeOption, fetchTypeOption]).then(response => {
        if (response[0].state !== '11') return
        if (response[1].state !== '11') return
        if (response[2].state !== '11') return
        this.textbookTree = (response[0].data || []).filter(el => el.textbookList && el.textbookList.length)
        this.themeOption = (response[1].data || []).filter(el => el.tags && el.tags.length).map(el => {
          el.id = el.tagCategoryId
          el.name = el.tagCategoryName
          return el
        })
        this.typeOption = response[2].data || []
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取叶子模块下的题组or题组单元列表
    getListPaperGroup(moduleId) {
      this.$axios({
        method: 'post',
        url: this.$urls.getListPaperGroup,
        data: { moduleId },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.state !== '11') return
        this.groupList = (response.data || []).map(el => {
          el.hasChildren = el.unitGroupList && !!el.unitGroupList.length
          el.unfold = false
          if (this.activeModule.moduleClassify === 2) {
            el.moduleContentType = 7
          }
          return el
        })
        this.echoData()
      }).catch(err => {
        console.log(err)
      })
    },
    // 查询趣味配音素材列表【分页】
    findSimpleVideoByModule(module) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.findSimpleVideoByModule,
        data: {
          moduleId: this.activeModule.id,
          page: module.pageIndex,
          pageSize: this.pageSize,
          textbookIds: this.dubFilterParam.textbookIds || [],
          tagIds: this.dubFilterParam.tagIds || [],
          themeTypes: this.dubFilterParam.themeTypes || []
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        this.loading = false
        if (response.state !== '11') return
        const { pageContents = [] } = response.data
        if (pageContents.length) {
          module.simpleList = module.simpleList.concat(pageContents)
          module.noMore = false
        } else {
          module.noMore = true
        }
        this.echoData()
      }).catch(err => {
        console.log(err)
      })
    },
    // 根据模块id查询专辑列表接口【分页】
    findVideoAlbumPageByModule(module) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.findVideoAlbumPageByModule,
        data: {
          page: module.pageIndex,
          pageSize: this.pageSize,
          // textbookIds: [], // 教材id集合 string
          // tagIds: [], // 标签id集合 string
          // themeTypes: [], // 主题类型集合 integer
          moduleId: this.activeModule.id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        this.loading = false
        if (response.state !== '11') return
        const { pageContents = [] } = response.data
        if (pageContents.length) {
          module.albumPageList = module.albumPageList.concat(pageContents)
          module.noMore = false
        } else {
          module.albumPageList = module.albumPageList.concat(pageContents)
          module.noMore = true
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取教辅书籍
    fetchModuleTreeByPid2(id, gradeId) {
      const data = {
        moduleId: id,
        gradeId: gradeId || sessionStorage.getItem('gradeTypeId'),
        provinceId: sessionStorage.getItem('provinceId'),
        cityId: sessionStorage.getItem('cityId')
      }
      const schoolId = sessionStorage.getItem('schoolId')
      if (schoolId) data.schoolId = schoolId
      this.$axios({
        method: 'post',
        url: this.$urls.getModuleTreeByPid,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.state !== '11') return
        this.bookList = response.data || []
      })
    },
    // 一级模块切换
    changeModule(module) {
      if (module.id === this.activeModule.id) return
      this.activeModule = module
      const { id, moduleClassify, moduleContentType } = module
      if (this.$route.query.id !== id) this.$router.replace({ query: { id } })
      this.moduleId = id
      this.classifyList = []
      this.leafModuleList = []
      this.leafModule = {}
      this.activeClassify = {}
      this.stemModuleList = []
      this.stemFirstLevelIndex = null
      this.sublevelModuleList = []
      this.stemModule = {}
      this.showBookDetail = true
      if (moduleClassify === 1 && [1, 4].includes(moduleContentType)) {
        // 普通模块--音标或拓展
        this.leafModule = module
        if (moduleContentType === 1) {
          // 音标
          this.getClassifyList('pron')
        } else if (moduleContentType === 4) {
          // 拓展
          this.getClassifyList('repeatAfter')
        }
      } else if (moduleClassify === 1 && moduleContentType === 5) {
        // 配音
        this.fetchMaterialOptionData()
        this.leafModuleList = _.cloneDeep(dubTypeEnum)
        this.changeLeafModule(this.leafModuleList[0])
      } else if (moduleClassify === 3) {
        // 教材
        this.fetchTextbookTreeList()
      } else if (moduleClassify === 2) {
        // 教辅
        this.gradeList = TeachingAssistantEnum[this.gradeTypeId]
        let book = localStorage.getItem('book') ? JSON.parse(localStorage.getItem('book')) : null
        if (book) {
          this.book = book
          this.showBookDetail = true
          this.stemModuleList = this.book.children || []
          this.leafModuleList = []
          this.processingStemModule(this.stemModuleList, 1)
          if (this.stemModuleList.length) this.changeStemModule(this.stemModuleList[0], 0)
        } else {
          this.book = {}
          this.showBookDetail = false
          this.changeGrade(this.gradeList[0])
        }
      } else {
        this.fetchModuleTreeByPid(id)
      }
    },
    // 年级切换
    changeGrade(grade) {
      this.gradeId = grade.id
      this.fetchModuleTreeByPid2(this.activeModule.id, this.gradeId)
    },
    // 选择书籍
    clickBook(book) {
      this.book = book
      localStorage.setItem('book', JSON.stringify(book))
      this.stemModuleList = this.book.children || []
      this.sublevelModuleList = []
      this.leafModuleList = []
      this.groupList = []
      this.stemFirstLevelIndex = null
      this.processingStemModule(this.stemModuleList, 1)
      if (this.stemModuleList.length) this.changeStemModule(this.stemModuleList[0], 0)
      this.showBookDetail = true
    },
    // 切换书籍
    switchoverBook() {
      this.showBookDetail = false
      this.changeGrade(this.gradeList[0])
    },
    // 切换枝干模块 -- 首层
    changeStemModule(module, index) {
      if (index === this.stemFirstLevelIndex) return
      this.stemFirstLevelIndex = index
      this.groupList = []
      if (module.children && module.children.length) {
        // 查找当前模块下是否有层级超过1层的子模块
        let obj = module.children.find(el => !el.isLeaf)
        if (obj) {
          // 子模块层级超过1层，页面按4层样式展示
          this.sublevelModuleList = module.children
          this.changeSublevelModule(this.sublevelModuleList[0])
        } else {
          // 子模块层级只有1层，页面按3层样式展示
          this.stemModule = module
          this.sublevelModuleList = []
          this.leafModuleList = module.children
          this.changeLeafModule(this.leafModuleList[0])
        }
      } else {
        this.stemModule = module
        this.sublevelModuleList = []
        this.leafModuleList = []
        if (this.activeModule.moduleClassify === 2) {
          this.leafModule = module
          this.getListPaperGroup(module.id)
        } else {
          this.leafModule = module
          this.changeLeafModule(module)
        }
      }
    },
    // 切换枝干模块 -- 次一层
    changeSublevelModule(module) {
      if (module.id === this.stemModule.id) return
      this.stemModule = module
      if (module.isLeaf) {
        this.leafModuleList = []
        this.changeLeafModule(module)
      } else {
        this.leafModuleList = module.children
        this.changeLeafModule(this.leafModuleList[0])
      }
    },
    // 切换 音标/拓展阅读 分类
    changeClassify(classify) {
      if (classify.classifyId === this.activeClassify.classifyId) return
      this.classifyList[this.activeClassify.index] = { ...this.activeClassify }
      this.activeClassify = classify
      if (this.activeModule.moduleContentType === 1) {
        if (!classify.pronList || !classify.pronList.length) this.fetchPronList(this.activeClassify)
      } else if (this.activeModule.moduleContentType === 4) {
        if (!classify.repeatAfterList || !classify.repeatAfterList.length) this.fetchRepeatAfterList(this.activeClassify)
      }
    },
    // 教材版本变更
    changeVersions(module) {
      if (module.id === this.versionsModule.id) return
      this.versionsModule = module
      if (module.textbookList && module.textbookList.length) {
        this.textbookModule = {}
        this.textbookUnitList = []
        this.changeTextbook(module.textbookList[0])
      } else {
        this.textbookModule = {}
        this.textbookUnitList = []
        this.leafModuleList = []
        this.leafModule = {}
        this.estimatedTime = 0
      }
    },
    // 教材书籍变更
    changeTextbook(module) {
      if (module.id === this.textbookModule.id) return
      this.textbookModule = module
      this.textbookUnitList = []
      this.fetchTextbookUnitList(module.id)
    },
    // 教材书籍单元变更
    changeTextbookUnit(module) {
      if (module.id === this.textbookUnitModule.id) return
      this.textbookUnitModule = module
      let textbookUnitIds = null
      if (module.children && module.children.length) {
        textbookUnitIds = module.children.map(el => el.id)
      }
      this.fetchListPaperGroup(this.textbookUnitModule.id, textbookUnitIds)
    },
    // 叶子模块变更
    changeLeafModule(module) {
      if (!_.isEmpty(this.leafModule)) this.leafModuleList[this.leafModule.index] = { ...this.leafModule }
      this.estimatedTime = 0
      if (this.activeModule.moduleClassify === 3) {
        switch (module.moduleContentType) {
          case 2:
            module.wordModuleData && module.wordModuleData.forEach(el => {
              el.allSelected = false
              el.selectedList = []
              el.typeList = []
            })
            break
          case 3:
            module.readModuleData && module.readModuleData.forEach(el => {
              el.content = this.common.evaluat(el.content)
              el.translate = this.common.evaluat(el.translate)
              el.typeList = []
              el.oldTypeList = []
            })
            break
          case 5:
            module.dubModuleData && module.dubModuleData.forEach(el => {
              el.selected = false
            })
            break
          case 6:
            module.groupModuleData && module.groupModuleData.forEach(el => {
              el.selected = false
            })
            break
          case 13:
            module.wordGroupModuleData && module.wordGroupModuleData.forEach(el => {
              el.allSelected = false
              el.selectedList = []
              el.typeList = []
            })
            break
          case 14:
            module.textbookGroupModuleData && module.textbookGroupModuleData.forEach(el => {
              el.content = this.common.evaluat(el.content)
              el.translate = this.common.evaluat(el.translate)
              el.typeList = []
              el.oldTypeList = []
            })
            break
          default:
            break
        }
        this.leafModule = module
        this.echoData()
      } else if (this.activeModule.moduleClassify === 1) {
        this.leafModule = module
        switch (this.leafModule.moduleContentType) {
          case 6:
            this.getListPaperGroup(this.leafModule.id)
            break
          case 5:
            if (module.key === 'simple') {
              this.dubFilterParam = {
                textbookIds: [],
                themeTypes: [],
                tagIds: []
              }
              if (!this.leafModule.simpleList || !this.leafModule.simpleList.length) {
                this.findSimpleVideoByModule(this.leafModule)
              }
            } else if (module.key === 'albumPage') {
              if (!this.leafModule.albumPageList || !this.leafModule.albumPageList.length) {
                this.findVideoAlbumPageByModule(this.leafModule)
              }
            }
            break
          default:
            break
        }
      } else if (this.activeModule.moduleClassify === 2) {
        this.leafModule = module
        this.getListPaperGroup(module.id)
      }
    },
    // 单词题组全选单词状态变更
    changeAllSelected(val, testlets) {
      testlets.allSelected = val
      if (val) {
        testlets.selectedList = testlets.wordList.map(el => el.wordId ? el.wordId : el.id)
      } else {
        testlets.selectedList = []
      }
      this.$forceUpdate()
      this.setEstimatedTime()
    },
    // 选择词汇
    selectWord(testlets, wordId) {
      if (testlets.selectedList.includes(wordId)) {
        testlets.selectedList = testlets.selectedList.filter(el => el !== wordId)
      } else {
        testlets.selectedList = [...testlets.selectedList, wordId]
      }
      this.$forceUpdate()
      this.setEstimatedTime()
    },
    // 选择题型
    changeType(testlets, id) {
      if (testlets.typeList.includes(id)) {
        testlets.typeList = testlets.typeList.filter(el => el !== id)
      } else {
        testlets.typeList = [...testlets.typeList, id]
      }
      this.$forceUpdate()
      this.setEstimatedTime()
    },
    // 配音素材筛选条件变更
    changeSimpleParams() {
      this.leafModule.simpleList = []
      this.leafModule.pageIndex = 1
      this.findSimpleVideoByModule(this.leafModule)
    },
    // 设置预计时间
    setEstimatedTime() {
      switch (this.leafModule.moduleContentType) {
        case 2:
          this.estimatedTime = this.leafModule.wordModuleData ? this.leafModule.wordModuleData.reduce((prev, cur) => Decimal(prev).add(Decimal(cur.selectedList.length).mul(Decimal(0.1)).mul(Decimal(cur.typeList.length))).toNumber(), 0) : 0
          break
        case 3:
          this.estimatedTime = this.leafModule.readModuleData ? this.leafModule.readModuleData.reduce((prev, cur) => Decimal(prev).add(Decimal(5).mul(Decimal(cur.typeList.length))).toNumber(), 0) : 0
          break
        case 13:
          this.estimatedTime = this.leafModule.wordGroupModuleData ? this.leafModule.wordGroupModuleData.reduce((prev, cur) => Decimal(prev).add(Decimal(cur.selectedList.length).mul(Decimal(0.1)).mul(Decimal(cur.typeList.length))).toNumber(), 0) : 0
          break
        case 14:
          this.estimatedTime = this.leafModule.textbookGroupModuleData ? this.leafModule.textbookGroupModuleData.reduce((prev, cur) => Decimal(prev).add(Decimal(5).mul(Decimal(cur.typeList.length))).toNumber(), 0) : 0
          break
        default:
          this.estimatedTime = 0
          break
      }
    },
    changeFold(groupModule) {
      groupModule.unfold = !groupModule.unfold
    },
    // 加入清单
    submit() {
      switch (this.leafModule.moduleContentType) {
        case 2:
          this.wordSubmit()
          break
        case 3:
          this.textSubmit()
          break
        case 13:
          this.newWordSubmit()
          break
        case 14:
          this.newTextSubmit()
          break
        default:
          break
      }
    },
    // 单词提交
    wordSubmit() {
      try {
        this.leafModule.wordModuleData.forEach(wordModule => {
          let wordList = []
          let typeList = []
          if (wordModule.selectedList?.length) {
            wordList = wordModule.wordList.filter(word => wordModule.selectedList.includes(word.wordId)).map(word => {
              return { id: word.wordId, name: word.wordName }
            })
            typeList = this.practiceMode[this.leafModule.moduleContentType].filter(type => wordModule.typeList.includes(type.id))
            if (wordList.length) {
              if (typeList?.length === 0) {
                throw new Error('choiceModel')
              }
              const obj = {
                id: wordModule.wordUnitId,
                name: '单词训练：' + wordModule.shortName,
                typeList,
                wordList,
                moduleId: this.leafModule.id,
                indexModuleName: this.leafModule.name || '',
                moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
                flag: wordModule.flag,
                time: Number(Decimal(wordList.length).mul(Decimal(typeList.length).mul(Decimal(0.1))))
              }
              this.$store.commit('listAdd', obj)
            }
          }
        })
      } catch (e) {
        if (e.message === 'choiceModel') {
          this.$alert('请选择练习模式', '提示', {
            confirmButtonText: '确定'
          })
        } else {
          throw e
        }
      }
    },

    // 新单词提交
    newWordSubmit() {
      try {
        this.leafModule.wordGroupModuleData.forEach(wordModule => {
          let wordList = []
          let typeList = []
          if (wordModule.selectedList?.length) {
            wordList = wordModule.wordList.filter(word => wordModule.selectedList.includes(word.id)).map(word => {
              return { id: word.id, name: word.name, materialType: word.materialType }
            })
            typeList = this.practiceMode[this.leafModule.moduleContentType].filter(type => wordModule.typeList.includes(type.id))
            if (wordList.length) {
              if (typeList?.length === 0) {
                throw new Error('choiceModel')
              }
              const obj = {
                id: wordModule.wordGroupId,
                name: '单词训练：' + wordModule.wordGroupName,
                typeList,
                wordList,
                moduleId: this.leafModule.id,
                materialType: wordModule.materialType,
                indexModuleName: this.leafModule.name || '',
                moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
                flag: wordModule.flag,
                time: Number(Decimal(wordList.length).mul(Decimal(typeList.length).mul(Decimal(0.1))))
              }
              this.$store.commit('listAdd', obj)
            }
          }
        })
      } catch (e) {
        if (e.message === 'choiceModel') {
          this.$alert('请选择练习模式', '提示', {
            confirmButtonText: '确定'
          })
        } else {
          throw e
        }
      }
    },

    // 课文
    textSubmit() {
      this.leafModule.readModuleData.forEach(readModule => {
        if (readModule.typeList && readModule.typeList.length) {
          const typeList = this.practiceMode[this.leafModule.moduleContentType].filter(type => readModule.typeList.includes(type.id))
          const obj = {
            pId: this.leafModule.id,
            id: readModule.repeatAfterId,
            name: readModule.shortName || readModule.repeatAfterName,
            flag: readModule.flag,
            indexModuleName: this.leafModule.name,
            moduleId: this.leafModule.id,
            moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
            typeList,
            time: Number(new Decimal(typeList.length).mul(Decimal(5)))
          }
          this.$store.commit('listAdd', obj)
        } else {
          if (readModule.oldTypeList && readModule.oldTypeList.length) {
            this.$store.commit('listDel', { id: readModule.repeatAfterId })
          }
        }
      })
    },

    // 新课文
    newTextSubmit() {
      this.leafModule.textbookGroupModuleData.forEach(readModule => {
        if (readModule.typeList && readModule.typeList.length) {
          const typeList = this.practiceMode[this.leafModule.moduleContentType].filter(type => readModule.typeList.includes(type.id))
          const obj = {
            pId: this.leafModule.id,
            id: readModule.id,
            name: readModule.name || readModule.shortName,
            flag: readModule.flag,
            indexModuleName: this.leafModule.name,
            materialType: readModule.materialType,
            moduleId: this.leafModule.id,
            moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
            typeList,
            allSentencesId: readModule.sentences.map(sentence => sentence.id),
            time: Number(new Decimal(typeList.length).mul(Decimal(5)))
          }
          this.$store.commit('listAdd', obj)
        } else {
          if (readModule.oldTypeList && readModule.oldTypeList.length) {
            this.$store.commit('listDel', { id: readModule.id })
          }
        }
      })
    },

    // 配音预览弹框--提交
    dubSubmit(dubModule) {
      if (!dubModule.selected) {
        const obj = {
          id: dubModule.materialId,
          name: dubModule.materialName,
          moduleId: this.activeModule.moduleClassify === 1 ? this.activeModule.id : this.leafModule.id,
          flag: 8,
          moduleName: this.activeModule.moduleClassify === 1 ? '趣味配音' : `${this.activeModule.name}>${this.leafModule.name}`,
          indexModuleName: this.leafModule.name,
          time: 5
        }
        this.$store.commit('listAdd', obj)
      } else {
        this.$store.commit('listDel', { id: dubModule.materialId })
      }
      dubModule.selected = !dubModule.selected
      this.$refs.dubDialogRef.changeVisible(false)
    },
    // 笔试/听说题组前往布置
    async toCrrangement(module) {
      if (this.activeModule.moduleClassify === 1) {
        switch (this.leafModule.moduleContentType) {
          case 1:
            this.$router.push({
              name: 'paperDetail',
              query: {
                id: module.id,
                name: module.name || '',
                flag: 2,
                indexModuleName: '音标学习',
                moduleId: this.activeModule.id,
                moduleName: this.activeModule.name,
                time: 1.5
              }
            })
            break
          case 6:
            // 不定层级
            let text = `${this.activeModule.name}>${this.stemModuleList[this.stemFirstLevelIndex].name}`
            /**
             * 最低层级的枝干模块id不等于第一层枝干模块id 同时不等于叶子模块id， 可以确定此时有4级
             */
            if (this.stemModule.id !== this.stemModuleList[this.stemFirstLevelIndex].id && this.stemModule.id !== this.leafModule.id) {
              text = `${text}>${this.stemModule.name}`
            }
            if (module.groupType === 2) {
              this.$router.push({
                name: 'written',
                query: {
                  id: module.id,
                  name: module.shortName || module.name,
                  indexModuleName: this.leafModule.name,
                  moduleId: this.leafModule.id,
                  moduleName: `${text}>${this.leafModule.name}`
                }
              })
            } else {
              const { time } = await this.fetchSimplePaperQuestions(module.id)
              this.$router.push({
                name: 'paperDetail',
                query: {
                  id: module.id,
                  name: module.shortName || module.name,
                  flag: 5,
                  indexModuleName: this.leafModule.name,
                  moduleId: this.leafModule.id,
                  moduleName: `${text}>${this.leafModule.name}`,
                  time
                }
              })
            }
            break
          default:
            break
        }
      } else if (this.activeModule.moduleClassify === 3) {
        // 教材
        if (this.leafModule.moduleContentType === 6) {
          // 听说、笔试题组
          if (module.groupType === 2) {
            this.$router.push({
              name: 'written',
              query: {
                id: module.groupId,
                name: module.shortName || module.groupName,
                indexModuleName: this.leafModule.name,
                moduleId: this.leafModule.id,
                moduleName: `${this.activeModule.name}>${this.leafModule.name}`
              }
            })
          } else {
            const { time } = await this.fetchSimplePaperQuestions(module.groupId)
            this.$router.push({
              name: 'paperDetail',
              query: {
                id: module.groupId,
                name: module.shortName || module.groupName,
                flag: 5,
                indexModuleName: this.leafModule.name,
                moduleId: this.leafModule.id,
                moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
                time
              }
            })
          }
        }
      } else if (this.activeModule.moduleClassify === 2) {
        // 听说、笔试题组
        if (module.groupType === 2) {
          this.$router.push({
            name: 'written',
            query: {
              id: module.id,
              name: module.shortName || module.groupName,
              indexModuleName: this.leafModule.name,
              moduleId: this.leafModule.id,
              moduleName: `${this.activeModule.name}>${this.leafModule.name}`
            }
          })
        } else {
          const { time } = await this.fetchSimplePaperQuestions(module.id)
          this.$router.push({
            name: 'paperDetail',
            query: {
              id: module.id,
              name: module.shortName || module.groupName,
              flag: 5,
              indexModuleName: this.leafModule.name,
              moduleId: this.leafModule.id,
              moduleName: `${this.activeModule.name}>${this.leafModule.name}`,
              time
            }
          })
        }
      }
    },
    // 获取题组下的预计时长
    fetchSimplePaperQuestions(groupId) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: 'post',
          url: this.$urls.getSimplePaperQuestions,
          headers: { 'Content-Type': 'application/json' },
          data: {
            groupId
          }
        }).then(response => {
          if (response.state !== '11') {
            reject()
            return
          }
          const { data = [] } = response
          let num = 0
          let Ids = []
          data.forEach(el =>{
            num = num + utils.takeTime(el.questionsTypeId)
            if (el.id) {
              Ids.push(el.id)
            }
          })
          resolve({ time: num, Ids })
        }).catch((err) => {
          reject('err')
        })
      })
    },
    // 加入或移除清单
    async addList(module, moduleId) {
      const indexModuleName = this.leafModule.name
      let time = 0
      let id, name, flag, moduleName, Ids
      if (this.activeModule.moduleClassify === 3) {
        if (this.leafModule.moduleContentType === 6) {
          const object = await this.fetchSimplePaperQuestions(module.groupId)
          id = module.groupId
          name = module.shortName || module.groupName
          flag = module.flag
          moduleName = `${this.activeModule.name}>${this.leafModule.name}`
          time = object.time
          Ids = object.Ids
        }
      } else if ([1, 2].includes(this.activeModule.moduleClassify)) {
        if (this.leafModule.moduleContentType === 1) {
          id = module.id
          name = module.name
          flag = 2
          moduleName = '音标学习'
          time = 1.5
        } else if ([6, 7].includes(this.leafModule.moduleContentType)) {
          // 不定层级
          let text = this.activeModule.name
          if (this.activeModule.moduleClassify === 2) {
            text = `${text}>${this.book.name}`
          }
          text = `${text}>${this.stemModuleList[this.stemFirstLevelIndex].name}`
          /**
           * 最低层级的枝干模块id不等于第一层枝干模块id 同时不等于叶子模块id， 可以确定此时有4级
           */
          if (this.stemModule.id !== this.stemModuleList[this.stemFirstLevelIndex].id && this.stemModule.id !== this.leafModule.id) {
            text = `${text}>${this.stemModule.name}`
          }
          const res = await this.fetchSimplePaperQuestions(module.id)
          time = res.time
          Ids = res.Ids
          id = module.id
          name = module.shortName || module.name
          flag = 5
          moduleName = `${text}>${this.leafModule.shortName || this.leafModule.name}`
        }
      }
      const obj = { id, name, flag, moduleName, indexModuleName, time, moduleId, Ids }
      if (!module.selected) {
        this.$store.commit('listAdd', obj)
      } else {
        this.$store.commit('listDel', obj)
      }
      module.selected = !module.selected
    },
    // 点击配音模块
    clickDub(dubModule) {
      this.dubModule = dubModule
      this.$axios({
        method: 'post',
        url: this.$urls.getSimpleDetailForShare,
        headers: { 'Content-Type': 'application/json' },
        data: {
          materialId: dubModule.materialId
        }
      }).then(response => {
        if (response.state !== '11') return
        this.dubDetail = response.data || {}
        if (this.dubDetail.materialId) this.$refs.dubDialogRef.changeVisible(true)
      }).catch((err) => {
        console.log(err)
      })
    },
    // 点击配音模块
    clickAlbum(albumPage) {
      this.albumPageData = albumPage
      this.$axios({
        method: 'post',
        url: this.$urls.getVideoAlbumDetail,
        headers: { 'Content-Type': 'application/json' },
        data: {
          albumId: albumPage.id
        }
      }).then(response => {
        if (response.state !== '11') return
        this.albumDeatil = response.data || {}
        this.echoData()
        if (this.albumDeatil.id) this.albumVisible = true
      }).catch((err) => {
        console.log(err)
      })
    },
    backtrack() {
      this.albumVisible = false
      this.albumDeatil = {}
    },
    // 点击拓展阅读
    clickRepeatAfter(repeatAfter) {
      this.$router.push({
        name: 'repeatAfter',
        query: {
          id: repeatAfter.id,
          name: repeatAfter.name,
          flag: 4,
          indexModuleName: this.leafModule.name,
          moduleName: '拓展阅读',
          moduleId: this.leafModule.id
        }
      })
    },

    // 无限滚动，下一页数据
    loadMore() {
      if (this.loading) return
      this.activeClassify.pageIndex += 1
      if (this.activeModule.moduleContentType === 1) {
        this.fetchPronList(this.activeClassify)
      } else if (this.activeModule.moduleContentType === 4) {
        this.fetchRepeatAfterList(this.activeClassify)
      }
    },

    // 无限滚动，下一页数据
    loadMoreList() {
      if (this.loading) return
      this.leafModule.pageIndex += 1
      if (this.leafModule.key === 'simple') {
        this.findSimpleVideoByModule(this.leafModule)
      } else if (this.leafModule.key === 'albumPage') {
        this.findVideoAlbumPageByModule(this.leafModule)
      }
    },
    /**
     * 处理枝干模块数据 -- 递归
     * @param stemList 枝干模块数据
     * @param hierarchy 层级
     */
    processingStemModule(stemList, hierarchy) {
      stemList.forEach(el => {
        el.hierarchy = hierarchy
        if (el.children && el.children.length) {
          el.isLeaf = false
          this.processingStemModule(el.children, hierarchy + 1)
        } else {
          el.isLeaf = true
        }
      })
    },
    // 获取已选择的作业
    listState() {
      const {list, flag} = this
      if (!list) return
      const selectedWorkList = this.$store.state.selectedWorkList
      const {preliminWritten} = this.common.globalData
      for (let i = 0, len = list.length; i < len; i += 1) {
        const {unitGroupList} = list[i]
        if (unitGroupList) {
          for (let k = 0, len = unitGroupList.length; k < len; k += 1) {
            const {topicType} = unitGroupList[k]
            if (topicType === '2') {
              if (preliminWritten.writtenObj) {
                const {id} = unitGroupList[k]
                const str = JSON.stringify(preliminWritten.writtenObj)
                const re = new RegExp(id, 'g')
                const arr = str.match(re)
                if (arr) {
                  unitGroupList[k].num = arr.length
                }
              }
            } else {
              unitGroupList[k].selected = false
              const {id} = unitGroupList[k]
              if (selectedWorkList[id]) unitGroupList[k].selected = true
            }
          }
          list[i].unitGroupList = unitGroupList
          this.$set(this.list, i, list[i])
        } else {
          const {topicType} = list[i]
          if (topicType === '2') {
            if (preliminWritten.writtenObj) {
              const {id} = list[i]
              const str = JSON.stringify(preliminWritten)
              const re = new RegExp(id, 'g')
              const arr = str.match(re)
              if (arr) {
                this.$set(this.list[i], 'num', arr.length)
              }
            } else {
              this.$set(this.list[i], 'num', 0)
            }
            if (Object.keys(selectedWorkList).includes(list[i].id)) {
              this.$set(this.list[i], 'num', -1)
            }
          } else {
            this.$set(this.list[i], 'selected', false)
            let num = 0
            if (flag === 14) { // 假期作业
              for (let item of Object.keys(selectedWorkList)) {
                if (list[i].id === item) {
                  if (selectedWorkList[item].flag === 7) {
                    this.$set(this.list[i], 'wordListLength', selectedWorkList[item].wordList.length)
                  } else {
                    list[i].selected = true
                    this.$set(this.list, i, list[i])
                  }
                  if (selectedWorkList[item].typeList) { // 拥有做题模式
                    this.$set(this.list[i], 'typeList', selectedWorkList[item].typeList)
                    if (selectedWorkList[item].typeList.some(item => item.active)) {
                      this.$set(this.list[i], 'columnShow', true)
                    }
                  }
                  break
                } else {
                  this.$set(this.list[i], 'wordListLength', 0)
                  this.$set(this.list[i], 'columnShow', false)
                }
              }
              if (Object.keys(selectedWorkList).length === 0) {
                this.$set(this.list[i], 'wordListLength', 0)
                this.$set(this.list[i], 'columnShow', false)
              }
            } else {
              for (let item of Object.keys(selectedWorkList)) {
                if ([3, 4].includes(flag)) {
                  if (list[i].id === selectedWorkList[item].pId) {
                    num += 1
                  }
                } else {
                  if (list[i].id === item) {
                    if (flag === 7) {
                      this.$set(this.list[i], 'wordListLength', selectedWorkList[item].wordList.length)
                    }
                    list[i].selected = true
                    this.$set(this.list, i, list[i])
                    break
                  } else {
                    if (flag === 7) {
                      this.$set(this.list[i], 'wordListLength', 0)
                    }
                  }
                }
              }
              if (Object.keys(selectedWorkList).length === 0) {
                this.$set(this.list[i], 'wordListLength', 0)
              }
              if ([3, 4].includes(flag)) {
                this.$set(this.list[i], 'num', num)
              }
            }
          }
        }
      }
    },
    // 教材下菜单数据是否有内容
    hasContent (module){
      const moduleContentMap = {
        2: 'wordModuleData',
        3: 'readModuleData',
        5: 'dubModuleData',
        6: 'groupModuleData',
        13: 'wordGroupModuleData',
        14: 'textbookGroupModuleData'
      }
      const { moduleContentType } = module
      if (Object.keys(moduleContentMap).includes(String(moduleContentType))){
        if (module[moduleContentMap[moduleContentType]] === null || !module[moduleContentMap[moduleContentType]].length){
          return false
        }
      } else if (!Object.keys(moduleContentMap).includes(String(moduleContentType))){
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
// 标签栏活跃的标签
@mixin card($background-color: #FFFFFF, $border-radius: 16px) {
  background-color: $background-color;
  border-radius: $border-radius
}
.assignWork {
  .guide-classes-create {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1100px;
    height: calc(100vh - 80px);
    background-color: #ffffff;
    padding-top: 233px;

    .icon-classes-none {
      width: 173px;
      height: 155px;
      background: url("../../assets/icon-classes-none.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 30px;
    }

    .link-to-classes {
      color: #309AF2;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .msg-bg {
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2003;
  }

  .msg-box {
    background-color: #fff;
    padding: 40px 28px;
    border-radius: 16px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 18px;
    line-height: 25px;
    white-space: pre-line;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2004;
  }
}
.scroll1 {
  height: calc(100vh - 80px - 54px);
  position: relative;
  z-index: 97;
  .assign {
    border-radius: 16px;
    background-color: #fff;
    //padding-bottom: 20px;
    .module {
      display: flex;
      flex-wrap: wrap;
      padding: 13px;

      .module-item {
        width: 198px;
        height: 68px;
        padding: 12px 20px;
        margin: 7px;
        font-size: 16px;
        border: 1px solid #E5E5E5;
        border-radius: 16px;
        cursor: pointer;
        transition: all .3s;
        @include flex(flex-start);
        &.item-active{
          background-color: #EAF4FD;
          color: #309AF2;
          border: 1px solid #309AF2;
        }
        &:hover {
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
          transform: translateY(-4px);
          color: #000000;
        }

        .module-item-icon {
          width: 42px;
          height: 42px;
          margin-right: 14px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .book-module-container {
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 14px 28px;

    .book-module-list {
      border-bottom: 1px solid #F1F1F1;
      margin-bottom: 28px;
      overflow-x: auto;
      display: flex;

      & + .book-module-list {
        border-top: 1px solid #F1F1F1;
        padding-top: 14px;
      }

      // 滚动条区域
      &::-webkit-scrollbar {
        width: 4px; // 竖向滚动条宽度
        height: 4px; // 横向向滚动条高度
        background-color: #FFFFFF;
      }

      // 滚动条里的滑块
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #DEDEDE;
      }

      // 滚动条里的滑块滑动的槽
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #FFFFFF;
      }

      .book-module-item {
        //width: 128px;
        height: 54px;
        padding: 9px 28px 14px;
        font-size: 18px;
        line-height: 25px;
        border-top: 6px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        color: #333333;
        border-radius: 8px 8px 0 0;
        white-space: nowrap;

        &.active {
          font-weight: bold;
          color: #309AF2;
          border-top-color: #309AF2;
          border-left-color: #F1F1F1;
          border-right-color: #F1F1F1;
        }
      }
    }
    .book-list{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -10px;
      .book-item{
        margin: 10px;
        width: 512px;
        height: 183px;
        padding: 20px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
          transform: translateY(-4px);
        }
        .book-item-img{
          width: 109px;
          height: 144px;
        }
        .book-item-content{
          margin-left: 20px;
          .book-item-name{
            font-size: 17px;
            line-height: 24px;
            color: #333333;
          }
        }
      }
    }

    .list-footer{
      display: block;
      margin-top: 30px;
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      color: #C8C8C8;
    }
  }
  .book-info{
    margin-top: 20px;
    padding: 10px 28px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    .book-main-photo{}
    .book-name{
      flex: 1;
      margin-left: 14px;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
    }
    .book-btn{
      padding: 9px 16px;
      color: #333333;
      border-radius: 21px;
      background-color: #F6F6F6;
      border: 1px solid #E5E5E5;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        background-color: #F1F1F1;
      }
      img{
        margin-left: 6px;
      }
    }
  }
  .stem-module-container{
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 14px 28px;
    .stem-module-list{
      overflow-x: auto;
      display: flex;
      &+.stem-module-list{
        border-top: 1px solid #F1F1F1;
        padding-top: 14px;
      }
      // 滚动条区域
      &::-webkit-scrollbar{
        width: 4px; // 竖向滚动条宽度
        height: 4px; // 横向向滚动条高度
        background-color: #FFFFFF;
      }
      // 滚动条里的滑块
      &::-webkit-scrollbar-thumb{
        border-radius: 4px;
        background-color: #DEDEDE;
      }
      // 滚动条里的滑块滑动的槽
      &::-webkit-scrollbar-track{
        border-radius: 4px;
        background-color: #FFFFFF;
      }
      .stem-module-item{
        //width: 128px;
        height: 54px;
        padding: 9px 28px 14px;
        font-size: 18px;
        line-height: 25px;
        border-top: 6px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        color: #333333;
        border-radius: 8px 8px 0 0;
        white-space: nowrap;
        cursor: pointer;
        &.active{
          font-weight: bold;
          color: #309AF2;
          border-top-color: #309AF2;
          border-left-color: #F1F1F1;
          border-right-color: #F1F1F1;
        }
      }
      .stem-module-children-item{
        height: 38px;
        padding: 7px 20px;
        border-radius: 19px;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        background-color: #FFFFFF;
        white-space: nowrap;
        cursor: pointer;
        &+.stem-module-children-item{
          margin-left: 14px;
        }
        &.active{
          font-weight: bold;
          color: #309AF2;
          background-color: #EAF4FD;
        }
      }
    }
  }
  .textbook-module{
    @include card();
    margin-top: 20px;
    padding: 20px 0;
    .level-item{
      display: flex;
      justify-content: flex-start;
      &+.level-item{
        margin-top: 20px;
      }
      .level-item-label{
        width: 96px;
        padding: 5px 28px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
      }
      .level-item-value{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        &+.textbookUnitModule{
          margin-top: 20px;
        }
        .module-item{
          height: 38px;
          max-width: 300px;
          padding: 7px 20px 6px;
          border-radius: 19px;
          color: #333333;
          font-size: 18px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &.active{
            background-color: #EAF4FD;
            color: #309AF2;
            font-weight: bold;
          }
        }
      }
    }
  }
  .modal-container{
    .modal-container-content{
      margin-top: 20px;
      display: flex;
      .leaf-modal-list{
        @include card();
        width: 180px;
        padding: 20px 0;
        margin-right: 20px;
        .leaf-modal{
          width: 180px;
          height: 60px;
          padding: 0 10px 0 4px;
          font-size: 18px;
          line-height: 60px;
          text-align: center;
          color: #333333;
          border-left-width: 6px;
          border-style: solid;
          border-left-color: #FFFFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &.active{
            color: #309AF2;
            background-color: #EAF4FD;
            border-left-color: #309AF2;
          }
        }
      }
      .leaf-modal-content{
        @include card();
        flex: 1;
        padding: 28px;
        height: fit-content;
        &.has-header{
          padding-top: 0;
        }
        .dub-testlets-header{
          width: 844px;
          height: 65px;
          padding: 10px 0;
          margin-bottom: 14px;
          display: flex;
          justify-content: flex-start;
          border-bottom: 1px solid #F1F1F1;
          &>span{
            max-width: 33%;
          }
        }
        .empty{
          text-align: center;
          padding: 120px 0;
          .empty-description{
            margin-top: 10px;
            font-size: 15px;
            line-height: 21px;
            color: #C8C8C8;
          }
        }
        .dub-testlets-list{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: -10px;
          .testlets{
            &.dub-testlets{
              width: 268px;
              height: 197px;
              border-radius: 16px;
              margin: 10px;
              background-color: #FFFFFF;
              padding: 14px;
              border: 1px solid #E5E5E5;
              cursor: pointer;
              &:hover {
                box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
                transform: translateY(-4px);
                color: #000000;
              }
              .testlets-cover{
                overflow: hidden;
                width: 240px;
                height: 135px;
                border-radius: 12px;
                position: relative;
                background-color: #F6F6F6;
                .testlets-cover-img{
                  width: 100%;
                  height: 100%;
                }
                .testlets-info{
                  font-size: 16px;
                  line-height: 22px;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 34px;
                  padding: 0 14px;
                  background: linear-gradient(180deg,rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.40) 100%);
                  color: #FFFFFF;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .testlets-info-img{
                    width: 18px;
                    height: 18px;
                  }
                  .sentence-count{
                    flex: 1;
                    margin-left: 4px;
                  }
                }
              }
              .testlets-name{
                margin-top: 10px;
                color: #333333;
                font-size: 17px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .album-list{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: -10px;
          .album-item{
            margin: 10px;
            width: 412px;
            height: 163px;
            padding: 14px;
            border-radius: 16px;
            border: 1px solid #E5E5E5;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
              box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
              transform: translateY(-4px);
              //color: #000000;
            }
            .album-item-img{
              width: 135px;
              height: 135px;
              border-radius: 16px;
            }
            .album-item-content{
              margin-left: 20px;
              .album-item-name{
                width: 243px;
                font-size: 17px;
                line-height: 24px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .album-item-num{
                margin-top: 10px;
                font-size: 14px;
                line-height: 20px;
                color: #999999;
              }
            }
          }
        }
        .album-deatil{
          .album-header{
            position: relative;
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #F1F1F1;
            margin-bottom: 20px;
            .album-head-photo{
              border-radius: 16px;
            }
            .album-info{
              flex: 1;
              margin-left: 20px;
              display: flex;
              flex-flow: column;
              justify-content: center;
              .album-name{
                color: #333333;
                font-size: 17px;
                line-height: 24px;
                margin-bottom: 10px;
                width: calc(100% - 180px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .album-author{
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .album-author-img{
                  border-radius: 13px;
                }
                .album-author-name{
                  margin-left: 8px;
                  color: #999999;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
            .backtrack{
              width: 180px;
              height: 44px;
              border-radius: 22px 0 0 22px;
              padding: 10px 20px;
              position: absolute;
              top: 0;
              right: -28px;
              display: flex;
              align-items: center;
              background: linear-gradient(90deg, #FAFAFA 0%, #F6F6F6 100%);
              cursor: pointer;
              .backtrack-value{
                color: #999999;
                font-size: 17px;
                line-height: 24px;
                margin-left: 10px;
              }
            }
          }
          .album-Material-list{

          }
        }
        .module-item{
          width: 100%;
          height: 78px;
          padding: 0 28px;
          border: 1px solid transparent;
          border-radius: 16px;
          &:hover{
            border: 1px solid #F1F1F1;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
            .module-item-container{
              border-bottom-color: transparent;
            }
          }
          .module-item-container{
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #F1F1F1;
            .module-item-name{
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              flex: 1;
            }
            .fold-icon{
              width: 65px;
              height: 34px;
              border-radius: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(0deg, #FBFBFB 0%, #F6F6F6 100%);
              &:hover{
                background: linear-gradient(0deg, #F1F1F1 0%, #F1F1F1 100%);
              }
            }
            .module-item-btn{
              //width: 88px;
              padding: 6px 14px;
              height: 34px;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              border-radius: 6px;
              background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
              color: #FFFFFF;
              cursor: pointer;
              &+.module-item-btn{
                margin-left: 14px;
              }
              &.delete{
                background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
                &:hover{
                  background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
                }
              }
              &.light-btn{
                background: #FFFFFF;
                color: #309AF2;
                &:hover{
                  background: #F6F6F6;
                }
              }
              &:hover{
              background: linear-gradient(90deg, #1485E3 0%, #1485E3 100%);
              }
            }
          }
        }
        .content-footer{
          padding-top: 28px;
          width: 100%;
          //text-align: center;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          .estimated-time{
            color: #999999;
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 6px;
          }
          .content-footer-btn{
            padding: 8px 31px;
            height: 40px;
            color: #FFFFFF;
            font-size: 17px;
            line-height: 24px;
            border-radius: 8px;
            background-color: #1485E3;
            cursor: pointer;
          }
        }
        .list-footer{
          display: block;
          margin-top: 30px;
          text-align: center;
          font-size: 15px;
          line-height: 21px;
          color: #C8C8C8;
        }
      }
    }
  }
}

.dub-description{
  margin-top: 10px;
  display: flex;
  .dub-description-value{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    &::before{
      content: '';
      float: right;
      height: 100%;
      margin-bottom: -20px;
    }
  }
  #exp {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  #exp:checked+.dub-description-value{
    -webkit-line-clamp: 999; /*设置一个足够大的行数就可以了*/
    .fold-btn::after{
      content:'';
      background: transparent url("../../assets/spread.png") no-repeat center center;
      background-size: 11px 5px;
      padding-right: 34px;
    }
  }
  .fold-btn {
    color: #309AF2;
    float: right;
    clear: both;
    margin-right: 8px;
    border-radius: 8px;
    width: 34px;
    &:hover{
      background: #F6F6F6
    }
    &::after{
      content:'';
      background: transparent url("../../assets/spread-2.png") no-repeat center center;
      background-size: 11px 5px;
      padding-right: 34px;

    }
  }
}
</style>
<style lang="scss">

.select-popover{
  padding: 0;
  border-radius: 16px;
}
</style>
