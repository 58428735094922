<template>
  <div class="testlets text-testlets">
    <div class="testlets-item">
      <div class="testlets-title mb-20">
        <span class="testlets-title-value" v-if="moduleContentType === 3">{{ testlets.shortName || testlets.repeatAfterName || ''}}</span>
        <span class="testlets-title-value" v-if="moduleContentType === 14">{{testlets.name || testlets.shortName || ''}}</span>
      </div>
      <div class="read-module-content">
        <input type="checkbox" :id="'exp_' + id" class="exp" />
        <div class="read-module-content-value">
          <label class="fold-btn" :for="'exp_' + id"></label>
          <span v-html="testlets.content" />
        </div>
      </div>
    </div>
    <div class="divider" />
    <div class="testlets-item">
      <div class="testlets-title  mb-20">
        <span class="testlets-title-value">选择题型</span>
      </div>
      <div class="type-list">
        <div v-for="(type) in practiceMode[moduleContentType]" :key="type.id" :class="['type-item', typeList.includes(type.id) ? 'active' : '']" @click="changeType(testlets, type.id)">
          <div class="type-item-name mb-20">{{ type.name }}</div>
          <div class="type-item-remark">{{ type.remark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PracticeModeEnum } from '@/utils/enum.js'

export default {
  name: 'TextModule',
  props: {
    moduleContentType: {
      type: Number
    },
    index: {
      type: [Number, String]
    },
    testlets: {
      type: Object,
      default: () => {}
    },
    typeList: {
      type: Array,
      default: () => []
    },
    id: {
      type: [Number, String],
      default: () => ''
    }
  },
  data() {
    return {
      practiceMode: PracticeModeEnum,
    }
  },
  methods: {
    // 选择题型
    changeType(testlets, id) {
      this.$emit('changeType', testlets, id)
    }
  }
}
</script>

<style lang="scss" scoped>
.testlets{
  background-color: #FFFFFF;
  border-radius: 24px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  padding: 20px 28px;
  &+.testlets{
    margin-top: 28px;
  }
  .testlets-item{
    .testlets-title{
      @include flex(space-between);
      width: 100%;
      .testlets-title-value{
        color: #333333;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
      }
    }
    .read-module-content{
      display: flex;
      .read-module-content-value{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        &::before{
          content: '';
          float: right;
          height: 100%;
          margin-bottom: -20px;
        }
      }
      .exp {
        visibility: hidden;
        aaa: var(--index)
      }
      .exp:checked+.read-module-content-value{
        -webkit-line-clamp: 999; /*设置一个足够大的行数就可以了*/
        .fold-btn::after{
          content:'收起';
          background: url("../../../assets/top_arrow.png") no-repeat right center;
          background-size: 11px 5px;
          padding-right: 15px;
        }
      }
      .fold-btn {
        color: #309AF2;
        float: right;
        clear: both;
        margin-right: 8px;
        &::after{
          content:'展开';
          background: url("../../../assets/bottom_arrow.png") no-repeat right center;
          background-size: 11px 5px;
          padding-right: 15px;
        }
      }
    }
    .type-list{
      @include flex(flex-start);
      flex-wrap: wrap;
      margin: -7px;
      .type-item{
        margin: 7px;
        padding: 20px;
        width: 253px;
        color: #333333;
        font-size: 18px;
        line-height: 24px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        cursor: pointer;
        &.active{
          font-weight: bold;
          background-color: #EAF4FD;
          border: 1px solid #309AF2;
          .type-item-name{
            color: #309AF2;
          }
          .type-item-remark{
            color: #309AF2;
          }
        }
        .type-item-name{
          color: #333333;
          font-size: 18px;
          line-height: 25px;
        }
        .type-item-remark{
          color: #999999;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
  .divider{
    margin: 20px 0;
    height: 1px;
    background-color: #F1F1F1;
  }
}
</style>
