// 模块内容类型
export const moduleContentTypeEnum = {
  1: '音标',
  2: '单词',
  3: '课文',
  4: '拓展',
  5: '配音',
  6: '听说读写',
  7: '书籍',
}
// 作业类型
export const homeWorkTypeEnum = {
  1: '单词',
  2: '音标',
  3: '跟读', // （课文）/拓展/趣味配音
  4: '笔试',
  5: '题组',
  6: '拓展作业',
  7: '新单词',
  8: '新趣味配音'
}
// 模块内容类型与作业类型映射 -- 模块内容类型moduleContentType -> 作业类型映射flag
export const flag2moduleContentTypeEnum = {
  1: 2, // '音标' -> '音标2';
  2: 7, // '单词' -> '新版单词练习';
  3: 3, // '课文' -> '跟读';
  4: 6, // '拓展' -> '拓展作业';
  5: 8, // '配音' ->  '新趣味配音';
  6: [4, 5], // '听说读写' -> '笔试'和'题组';
  // 7:  // 模块类型：'书籍'；作业类型： '';
  13: 10, // '新单词' -> '单词短语';
  14: 24 // '新课文听读' -> '新课文';
}
// 模块内容类型与作业类型映射 -- 作业类型映射flag -> 模块内容类型moduleContentType
export const moduleContentType2flagEnum = {
  // 1: , // '单词' ->
  2: 1, // '音标' -> 音标
  3: 3, // '跟读', -> 课文
  4: 6, // '笔试' -> 听说读写
  5: 6, // '题组' -> 听说读写
  6: 4, // '拓展作业' -> 拓展
  7: 2, // '新单词' -> 单词
  8: 5, // '新趣味配音' -> 配音
}

// 各题型练习模式
export const PracticeModeEnum = {
  2: [
    { id: 1, shortName: '朗', name: '朗读单词', remark: '逐个朗读单词，精准纠正发音' },
    { id: 5, shortName: '识', name: '识读音标', remark: '辨识音标对应单词，并进行纠音' },
    { id: 2, shortName: '义', name: '看词选义', remark: '选择所给单词的正确释义' },
    { id: 3, shortName: '词', name: '看义选词', remark: '选择所给释义的正确单词' },
    { id: 6, shortName: '听', name: '听词选义', remark: '选择所听单词的正确释义' },
    { id: 4, shortName: '拼', name: '听音拼写', remark: '听录音，拼写所听到的单词' }
  ],
  3: [
    { id: 2, name: '逐句跟读', remark: '跟随课文示范录音逐句读短文' },
    { id: 1, name: '通篇朗读', remark: '朗读整篇短文的内容' },
    { id: 5, name: '逐句复述', remark: '逐句复述所听到的短文内容' },
  ],
  4: [
    { id: 2, name: '逐句跟读', remark: '跟随课文示范录音逐句读短文' },
    { id: 1, name: '通篇朗读', remark: '朗读整篇短文的内容' },
    { id: 5, name: '逐句复述', remark: '逐句复述所听到的短文内容' },
  ],
  7: [
    { id: 1, shortName: '朗', name: '朗读单词', remark: '逐个朗读单词，精准纠正发音' },
    { id: 5, shortName: '识', name: '识读音标', remark: '辨识音标对应单词，并进行纠音' },
    { id: 2, shortName: '义', name: '看词选义', remark: '选择所给单词的正确释义' },
    { id: 3, shortName: '词', name: '看义选词', remark: '选择所给释义的正确单词' },
    { id: 6, shortName: '听', name: '听词选义', remark: '选择所听单词的正确释义' },
    { id: 4, shortName: '拼', name: '听音拼写', remark: '听录音，拼写所听到的单词' }
  ],
  // 8: [
  //   { id: 1, name: '基础练习', remark: '' },
  //   { id: 2, name: '听力练习', remark: '' },
  //   { id: 3, name: '趣味配音', remark: '' },
  // ],
  13: [
    { id: 1, shortName: '朗', name: '朗读单词', remark: '逐个朗读单词，精准纠正发音' },
    { id: 5, shortName: '识', name: '识读音标', remark: '辨识音标对应单词，并进行纠音' },
    { id: 2, shortName: '义', name: '看词选义', remark: '选择所给单词的正确释义' },
    { id: 3, shortName: '词', name: '看义选词', remark: '选择所给释义的正确单词' },
    { id: 6, shortName: '听', name: '听词选义', remark: '选择所听单词的正确释义' },
    { id: 4, shortName: '拼', name: '听音拼写', remark: '听录音，拼写所听到的单词' }
  ],
  14: [
    { id: 2, name: '逐句跟读', remark: '跟随课文示范录音逐句读短文' },
    { id: 1, name: '通篇朗读', remark: '朗读整篇短文的内容' },
    { id: 5, name: '句子背诵', remark: '逐句背诵课文内容' },
  ],
}
/**
 * 拓展练习指定模块id
 * @type {{ '7': string,'8': string }}
 * 7：初中拓展模块id；8：高中拓展模块id
 */
export const moduleIdByGradeTypeIdeEnum = {
  7: '72B3DBA3A89F436A9FD765114A724DA5',
  8: '607D0C5A8FD44C91834071857F9BD61A'
}
// 趣味配音分类枚举
export const dubTypeEnum = [
  { id: 1, index: 0, pageIndex: 1, key: 'simple', name: '视频片段', moduleContentType: 5, simpleList: [] },
  { id: 2, index: 1, pageIndex: 1, key: 'albumPage', name: '视频专辑', moduleContentType: 5, albumPageList: [] }
]
// 教辅的分类
export const TeachingAssistantEnum = {
  7: [
    // {id: 7, name: '全部'},
    {id: 1, name: '七年级'},
    {id: 2, name: '八年级'},
    {id: 3, name: '九年级'}
  ],
  8: [
    // {id: 8, name: '全部'},
    {id: 4, name: '高一'},
    {id: 5, name: '高二'},
    {id: 6, name: '高三'}
  ]
}
