<template>
  <el-dialog
    :visible.sync="dubDialogVisible"
    width="960px"
    class="dub-dialog-wrapper"
    custom-class="dub-dialog-detail"
    top="0"
    :destroy-on-close="true"
  >
    <div class="dub-dialog-content">
      <div class="video-box">
        <video :src="dubDetail.videoUrl" controls="controls" width="904">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="dub-title">{{ dubDetail.materialName }}</div>
      <div class="dub-tag">
        <div v-for="tag in dubDetail.tagList" :key="tag.id" class="tag-item">{{ tag.name }}</div>
      </div>
      <div class="dub-description">
        <input type="checkbox" id="exp" />
        <div class="dub-description-value">
          <label class="fold-btn" for="exp"></label>
          <span v-html="dubDetail.description" />
        </div>
      </div>
    </div>
    <div class="dub-footer">
      <div class="dub-estimated-time">预计用时：5分钟</div>
      <div class="dub-footer-btn-row">
        <div :class="['dub-footer-btn', dubModule.selected ? 'delete' : '']" @click="dubSubmit(dubModule)">{{ dubModule.selected ? '移除清单' : '加入清单' }}</div>
        <div class="dub-footer-btn light-btn" @click="dubDialogVisible = false">取消</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DubDialog',
  props: {
    dubDetail: {
      type: Object,
      default: () => {}
    },
    dubModule: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dubDialogVisible: false
    }
  },
  methods: {
    changeVisible(bol) {
      this.dubDialogVisible = bol
    },
    dubSubmit(dubModule) {
      this.$emit('submit', dubModule)
    }
  }
}
</script>

<style lang="scss" scoped>
.dub-dialog-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.dub-description{
  margin-top: 10px;
  display: flex;
  .dub-description-value{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    &::before{
      content: '';
      float: right;
      height: 100%;
      margin-bottom: -20px;
    }
  }
  #exp {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  #exp:checked+.dub-description-value{
    -webkit-line-clamp: 999; /*设置一个足够大的行数就可以了*/
    .fold-btn::after{
      content:'';
      background: transparent url("../../../assets/spread.png") no-repeat center center;
      background-size: 11px 5px;
      padding-right: 34px;
    }
  }
  .fold-btn {
    color: #309AF2;
    float: right;
    clear: both;
    margin-right: 8px;
    border-radius: 8px;
    width: 34px;
    &:hover{
      background: #F6F6F6
    }
    &::after{
      content:'';
      background: transparent url("../../../assets/spread-2.png") no-repeat center center;
      background-size: 11px 5px;
      padding-right: 34px;

    }
  }
}
</style>
<style lang="scss">

.select-popover{
  padding: 0;
  border-radius: 16px;
}
.dub-dialog-detail{
  //background: red;
  margin: 0;
  max-height: 100vh;
  border-radius: 24px;
  overflow: hidden;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    //height: 100%;
    //padding: 28px 0 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    .dub-dialog-content{
      margin-top: 28px;
      position: relative;
      padding: 0 28px 20px;
      //height: calc(100% - 102px);
      max-height: calc(100vh - 170px);
      overflow-y: auto;
      .video-box{
        margin: 0 auto;
        //width: 904px;
        //height: 508px;
        border-radius: 12px;
        font-size: 0;
        overflow: hidden;
      }
      .dub-title{
        margin-top: 20px;
        color: #333333;
        font-size: 20px;
        line-height: 28px;
      }
      .dub-tag{
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tag-item{
          height: 25px;
          font-size: 12px;
          line-height: 17px;
          padding: 4px 10px;
          border-radius: 25px;
          background-color: #FFF1ED;
          color: #FF7C53;
          &+.tag-item{
            margin-left: 14px;
          }
        }
      }
    }
    .dub-footer{
      //position: absolute;
      //left: 0;
      //bottom: 0;
      padding-bottom: 20px;
      height: 102px;
      width: 100%;
      background-color: #FFFFFF;
      //margin-top: 20px;
      border-top: 1px solid #F1F1F1;
      .dub-estimated-time{
        margin-top: 14px;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        color: #999999;
      }
      .dub-footer-btn-row{
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        .dub-footer-btn{
          width: 130px;
          height: 40px;
          padding: 8px 0;
          border-radius: 8px;
          background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
          color: #FFFFFF;
          font-size: 17px;
          line-height: 24px;
          text-align: center;
          cursor: pointer;
          &+.dub-footer-btn{
            margin-left: 14px;
          }
          &.delete{
            background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
            &:hover{
              background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
            }
          }
          &.light-btn{
            color: #333333;
            background: linear-gradient(90deg, #F6F6F6 0%, #F6F6F6 100%);
            &:hover{
              background: linear-gradient(90deg, #F1F1F1 0%, #F1F1F1 100%);
            }
          }
          &:hover{
            background: linear-gradient(90deg, #1485E3 0%, #1485E3 100%);
          }
        }
      }
    }
  }
}
</style>
