<template>
  <div class="testlets word-testlets">
    <div class="testlets-item">
      <div class="testlets-title mb-20">
        <span class="testlets-title-value">第一步：选择词汇</span>
        <el-checkbox v-model="testlets.allSelected" :indeterminate="!!selectedList.length && selectedList.length < testlets.wordList.length" @change="(val) => changeAllSelected(val, testlets)">全选</el-checkbox>
      </div>
      <div class="word-list" v-if="moduleContentType === 2">
        <div v-for="word in testlets.wordList" :key="word.wordId" :class="['word-item', selectedList.includes(word.wordId) ? 'active' : '']" @click="selectWord(testlets, word.wordId, index)">{{ word.wordName }}</div>
      </div>
      <div class="word-list" v-if="moduleContentType === 13">
        <div v-for="word in testlets.wordList" :key="word.id" :class="['word-item', selectedList.includes(word.id) ? 'active' : '']" @click="selectWord(testlets, word.id, index)">{{ word.name }}</div>
      </div>
    </div>
    <div class="divider" />
    <div class="testlets-item">
      <div class="testlets-title  mb-20">
        <span class="testlets-title-value">第二步：选择题型</span>
      </div>
      <div class="type-list">
        <div v-for="(type) in practiceMode[moduleContentType]" :key="type.id" :class="['type-item', typeList.includes(type.id) ? 'active' : '']" @click="changeType(testlets, type.id)">
          <div class="type-item-name mb-20">{{ type.name }}</div>
          <div class="type-item-remark">{{ type.remark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PracticeModeEnum } from '@/utils/enum.js'

export default {
  name: 'WordModule',
  props: {
    moduleContentType: {
      type: Number
    },
    index: {
      type: [Number, String]
    },
    testlets: {
      type: Object,
      default: () => {}
    },
    selectedList: {
      type: Array,
      default: () => []
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      practiceMode: PracticeModeEnum,
    }
  },
  methods:{
    // 全选
    changeAllSelected(val, testlets) {
      this.$emit('changeAllSelected', val, testlets)
    },
    // 选择词汇
    selectWord(testlets, wordId) {
      this.$emit('selectWord', testlets, wordId)
    },
    // 选择题型
    changeType(testlets, id) {
      this.$emit('changeType', testlets, id)
    }
  }
}
</script>

<style lang="scss" scoped>
// 标签栏活跃的标签
@mixin card($background-color: #FFFFFF, $border-radius: 16px) {
  background-color: $background-color;
  border-radius: $border-radius
}
.word-testlets{
  &+.word-testlets{
    margin-top: 28px;
  }
}
.testlets{
  @include card(#FFFFFF, 24px);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  padding: 20px 28px;
  .testlets-item{
    .testlets-title{
      @include flex(space-between);
      width: 100%;
      .testlets-title-value{
        color: #333333;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
      }
    }
    .word-list{
      @include flex(flex-start);
      flex-wrap: wrap;
      margin: -7px;
      .word-item{
        margin: 7px;
        padding: 7px 14px;
        color: #333333;
        font-size: 18px;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        cursor: pointer;
        &.active{
          font-weight: bold;
          color: #309AF2;
          background-color: #EAF4FD;
          border: 1px solid #309AF2;
        }
      }
    }
    .type-list{
      @include flex(flex-start);
      flex-wrap: wrap;
      margin: -7px;
      .type-item{
        margin: 7px;
        padding: 20px;
        width: 253px;
        color: #333333;
        font-size: 18px;
        line-height: 24px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        cursor: pointer;
        &.active{
          font-weight: bold;
          background-color: #EAF4FD;
          border: 1px solid #309AF2;
          .type-item-name{
            color: #309AF2;
          }
          .type-item-remark{
            color: #309AF2;
          }
        }
        .type-item-name{
          color: #333333;
          font-size: 18px;
          line-height: 25px;
        }
        .type-item-remark{
          color: #999999;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
  .divider{
    margin: 20px 0;
    height: 1px;
    background-color: #F1F1F1;
  }
}
</style>
